import { Button, Stack } from '@mui/material';
import { t } from 'i18next';
import { useNavigate } from 'react-router-dom';
import EventBus from '../../../common/EventBus';

interface Props {
  open_modal?: () => void;
  validate?: () => Promise<boolean>;
  text_confirm: string;
}

const ActionRequest: React.FC<Props> = (props) => {
  const navigate = useNavigate();
  const handleClick = () => {
    EventBus.dispatch('modalIsClose');
    navigate('/dashboard');
  };

  return (
    <Stack direction="row" spacing={2} paddingTop={2} data-testid='action-request'>
      <Button
        id='cancel'
        data-testid='cancel'
        onClick={handleClick}
        variant='outlined'
        size='large'
      >
        {t('form.action.cancel')}
      </Button>
      <Button
        id='send'
        data-testid='send'
        onClick={async () => {
          if (props.validate && (await props.validate())) {
            EventBus.dispatch('modalIsOpen');
          }
          if (!props.validate) {
            EventBus.dispatch('modalIsOpen');
          }
        }}
        size='large'
      >
        {props.text_confirm}
      </Button>
    </Stack>
  );
};

export default ActionRequest;
