import React from 'react';
import profile from '../../../../assets/img/icon/profile.svg';
import children from '../../../../assets/img/icon/children.svg';
import infant from '../../../../assets/img/icon/infant.svg';
import childSeat from '../../../../assets/img/icon/child-seat.svg';
import babySeat from '../../../../assets/img/icon/baby-seat.svg';
import cabinBaggage from '../../../../assets/img/icon/cabin-baggage.svg';
import checkedBaggage from '../../../../assets/img/icon/checked-baggage.svg';
import whellchair from '../../../../assets/img/icon/whellchair.svg';
import ArticleView from '../../../../components/article/ArticleView.component';
import IPassengerDetails from '../../../../types/passenger-details.type';
import usePassengerDetailsView from '../../hook/passenger-details/use-passenger-details-view.hook';

interface Props {
  passager?: IPassengerDetails;
  light?: boolean;
}

const PassengerDetailsViewComponent: React.FC<Props> = (props) => {
  const {
    t,
    passagerDetails,
  } = usePassengerDetailsView(props);

  return (
    <div className='passager-details-view'>
      <div className='passager'>
        {!!passagerDetails.adults && passagerDetails.adults > 0 && (
          <ArticleView
            sufIcon={profile}
            title={t(`form.passenger.details.adult`)}
            value={passagerDetails.adults}
            light={props.light}
          />
        )}
        {!!passagerDetails.childrens && passagerDetails.childrens > 0 && (
          <ArticleView
            sufIcon={children}
            title={t(`form.passenger.details.children`)}
            value={passagerDetails.childrens}
            light={props.light}
          />
        )}
        {!!passagerDetails.childSeats && passagerDetails.childSeats > 0 && (
          <ArticleView
            sufIcon={childSeat}
            title={t(`form.passenger.details.child-seat`)}
            value={passagerDetails.childSeats}
            light={props.light}
          />
        )}
        {!!passagerDetails.infants && passagerDetails.infants > 0 && (
          <ArticleView
            sufIcon={infant}
            title={t(`form.passenger.details.infant`)}
            value={passagerDetails.infants}
            light={props.light}
          />
        )}
        {!!passagerDetails.babySeats && passagerDetails.babySeats > 0 && (
          <ArticleView
            sufIcon={babySeat}
            title={t(`form.passenger.details.baby-seat`)}
            value={passagerDetails.babySeats}
            light={props.light}
          />
        )}
      </div>
      <div className='luggage'>
        {!!passagerDetails.cabinBaggages && passagerDetails.cabinBaggages > 0 && (
          <ArticleView
            sufIcon={cabinBaggage}
            title={t(`form.passenger.details.cabin-baggage`)}
            value={passagerDetails.cabinBaggages}
            light={props.light}
          />
        )}
        {!!passagerDetails.checkedBaggages && passagerDetails.checkedBaggages > 0 && (
          <ArticleView
            sufIcon={checkedBaggage}
            title={t(`form.passenger.details.checked-baggage`)}
            value={passagerDetails.checkedBaggages}
            light={props.light}
          />
        )}
        {!!passagerDetails.whellchairs && passagerDetails.whellchairs > 0 && (
          <ArticleView
            sufIcon={whellchair}
            title={t(`form.passenger.details.wheelchair`)}
            value={passagerDetails.whellchairs}
            light={props.light}
          />
        )}
      </div>
    </div>
  );
};

export default PassengerDetailsViewComponent;
