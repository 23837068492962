import { ReactComponentElement } from 'react';
import { Stack, Typography } from '@mui/material';

export interface MissionRowPassengerIconProps {
  count?: number;
  icon?: ReactComponentElement<any>;
}

const MissionRowPassengerIcon = (props: MissionRowPassengerIconProps) => {
  return <>
    {(props.count && props.count > 0) &&
      <Stack direction="row" spacing={0.2} gap={'4px'} alignItems="center">
        <Typography variant="body2">{props.count}</Typography>
        {props.icon}
      </Stack>
    }
  </>;
};

export default MissionRowPassengerIcon;