import React from 'react';
import i18n, { t } from 'i18next';
import IMission from '../../types/mission.type';
import TripViewComponent, { TripViewMode } from '../trip/TripView.component';
import { transformTime } from '../../helpers/TransformDate.helper';
import ellipse from '../../assets/images/ellispe.svg';
import PassengerDetailsViewComponent from '../../domain/passenger/component/passenger-details/PassengerDetailsView.component';
import useDateFormatting from '../../common/date/use-date-formatting.hook';
import getDispatch from '../../config/userDispatch';
import useCivilities from '../../domain/passenger/hook/use-civilities.hook';

interface Props {
  mission: IMission;
}

const MissionViewComponent: React.FC<Props> = (props) => {
  const {
    transformDateToLocaleString,
  } = useDateFormatting();

  const {
    translateCivilityByIdAndDispatch
  } = useCivilities();

  const dispatch = getDispatch();

  return (
    <div className='mission-view'>
      {props.mission?.folderId && (
        <div className='mission-view__info'>
          <p className='mission-view__info-label'>{t('form.folder.title')} : </p>
          <p>{props.mission?.folderId}</p>
        </div>
      )}
      <div className='mission-view__info'>
        <p className='mission-view__info-label'>
          {t(`form.service.${props.mission.service?.type}.title`.toLowerCase(), '')} :{' '}
        </p>
        <p className='mission-view__info-value'>
          <TripViewComponent steps={props.mission.steps?.filter(step => !step.delete)} mode={TripViewMode.inline} />
        </p>
      </div>
      <div className='mission-view__info'>
        <p className='mission-view__info-label'>{t('form.quotes.date')}</p>
        <p className='mission-view__info-value'>{transformDateToLocaleString(props.mission.pickupDate, i18n.language)}</p>
      </div>
      <div className='mission-view__info'>
        <p className='mission-view__info-label'>{t('form.quotes.time')}</p>
        <p className='mission-view__info-value'>
          {transformTime(props.mission.pickupTime)}
          {props.mission?.service?.type?.toLowerCase() === 'transport' && (
            <>
              {' '}<img src={ellipse} alt='ellipse' />{' '}
              {transformTime(props.mission.dropoffTime)}
            </>
          )}
        </p>
      </div>
      <div className='mission-view__info'>
        <p className='mission-view__info-label'>{t('form.quotes.passenger')}</p>
        <p className='mission-view__info-value'>
          {props.mission?.passengers?.filter(passenger => passenger.delete !== true).map((passenger) => {
            return <>
              {dispatch && props.mission.passengers[0].civilityId && (translateCivilityByIdAndDispatch(props.mission.passengers[0].civilityId, dispatch)?.concat(' ') ?? '')}  
              {passenger.lastname ? passenger.lastname + ' ' : ''}
              {passenger.firstname ? passenger.firstname : ''}
              <div>
                <PassengerDetailsViewComponent
                  passager={passenger}
                  light={true}
                />
              </div>
              <br />
            </>
          })}
        </p>
      </div>
      <div className='mission-view__info'>
        <p className='mission-view__info-label'>{t('form.quotes.car')}</p>
        <p className='mission-view__info-value'>{props.mission.vehicle?.type?.label}</p>
      </div>
      {props.mission?.externalReference && (
        <div className='mission-view__info'>
          <p className='mission-view__info-label'>{t('form.projectReference.title')} : </p>
          <p className='mission-view__info-value'>{props.mission?.externalReference}</p>
        </div>
      )}
    </div>
  );
};

export default MissionViewComponent;
