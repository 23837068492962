import React, { useEffect, useState } from 'react';
import InputText from '../form/inputs/InputText.component';
import { useMsal } from '@azure/msal-react';
import { getAccessToken } from '../../utils/getAccessToken';
import PassengerService from '../../services/passengers/passenger.service';
import IPassenger from '../../types/passenger.type';
import passengerIcon from '../../assets/img/icon/People.svg';
import searchIcon from '../../assets/img/icon/search.svg';
import PassengerItem from '../passenger-item/PassengerItem.component';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';
import errorService from '../../common/services/notification/error.service';
import ErrorDisplay from '../../common/components/error-display.component';

interface Props {
  activePassenger?: IPassenger | null;
  edit?: (passenger: IPassenger) => void;
}

const PassengerSearch: React.FC<Props> = (props) => {
  const { instance } = useMsal();
  const [passenger, setPassenger] = useState('');
  const [passengers, setPassengers] = useState<IPassenger[]>();
  const { t } = useTranslation('ns1', { i18n });

  useEffect(() => {
    handleGetPassengers('');
  }, []);

  useEffect(() => {
    handleGetPassengers(passenger);
  }, [props.activePassenger]);

  const handleGetPassengers = async (e: string) => {
    getAccessToken(instance).then((token) => {
      if (!token) {
        console.warn("No access token found");
        return;
      }
      PassengerService.getPassengers(token, e)
        .then((response) => {
          errorService.clearErrorsByType('passengerSearch');
          setPassengers(response as IPassenger[]);
        })
        .catch((error) => {
          errorService.addError('passengerSearch', `errors.list.passengers.get`);
        });
    });
  };

  const handle_click = (value: IPassenger) => {
    if (props.edit) {
      props.edit(value);
    }
  };

  return (
    <div className='passenger-search'>
      <InputText
        label={''}
        name={'test'}
        onChange={async function (e: React.ChangeEvent<HTMLInputElement>) {
          setPassenger(e.target.value);
          await handleGetPassengers(e.target.value);
        }}
        id={'passenger-search'}
        class_name={'passenger__input '}
        placeholder={t('form.passenger.myPassengersPlaceholder')}
        prefix={passengerIcon}
        suffix={searchIcon}
        data-testid={'passenger-search'}
        value={passenger}
      />

      <ErrorDisplay errorType='passengerSearch' />

      {passengers && (
        <div className='passenger-search__results'>
          {Object.entries(passengers).map(([key, value]: [string, IPassenger], index) => (
            <div
              onClick={() => {
                handle_click(value);
              }}
              key={value.id}
              data-testid={'passenger-' + value.id}
            >
              <PassengerItem
                passenger={value}
                bg_color={index % 2 == 0 ? '' : 'item-bg-dark'}
                active={value.id === props.activePassenger?.id}
              />
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default PassengerSearch;
