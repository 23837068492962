import React from 'react';
import { t } from 'i18next';
import IMission from '../../../types/mission.type';
import MissionViewComponent from '../../mission/MissionView.component';
import { Button, Stack } from '@mui/material';

interface Props {
  quote?: object;
  mission: IMission;
  id: number;
  handleCancel?: () => void;
  handleRefuse?: (command_id: number, mission_id: number) => void;
  handleApprove?: (command_id: number) => void;
  kind: string;
}

const Cancel: React.FC<Props> = (props) => {
  return (
    <div className='cancel' data-testid='cancel-component'>
      <h5 className='cancel__title'>{t(`form.${props.kind}.title`)}</h5>
      <div className='cancel__info'>
        <p className='cancel__info-title'>{t(`form.${props.kind}.your_request`)}</p>
        <MissionViewComponent mission={props.mission} />
        <div className=''>
          <hr />
          <p className='confirmation__info-ref body-small'></p>
          <p className='confirmation__info-text body-small'>
            <strong>{t(`form.${props.kind}.please_cancel`)}</strong>
          </p>
        </div>
      </div>
      <Stack direction="row" spacing={2} paddingTop={2} sx={{ margin: '0 4rem 2rem 4rem', justifyContent: "center" }}>
        <Button
          id='cancel-button'
          onClick={() => {
            props.handleApprove && props.handleApprove(props.id);
          }}
          variant='outlined'
        >
          {t(`form.${props.kind}.confirm`)}
        </Button>
        <Button
          id='confirm-cancel-button'
          data-testid='confirm-cancel-button'
          onClick={() => {
            props.handleRefuse &&
            props.handleRefuse(props.id, parseInt(props.mission.id ?? ''));
          }}
        >
          {t(`form.${props.kind}.cancel`)}
        </Button>
      </Stack>
    </div>
  );
};

export default Cancel;
