import React from 'react';
import IPassenger from '../../../../types/passenger.type';
import InputText from '../../../../components/form/inputs/InputText.component';
import UserLogo from '../../../../assets/images/userInputLogo.svg';
import UserAddIcon from '../../../../assets/img/icon/user-add.svg';
import UserEditIcon from '../../../../assets/img/icon/user-edit.svg';
import EditIcon from '../../../../assets/img/icon/edit.svg';
import PassengerItem from '../../../../components/passenger-item/PassengerItem.component';
import FormLabel from '../../../../components/form/form-label/FormLabel.component';
import usePassengerCredentials from '../../hook/passenger-details/use-passenger-credentials.hook';
import InputPhone from "../../../../common/components/input-phone.component";
import { Box, FormControl, MenuItem, Select } from '@mui/material';
import useCivilities from '../../hook/use-civilities.hook';

interface Props {
  passenger: IPassenger;
  handleGetPassenger: (value?: string) => void;
  handleClickOnInputSuffix: (passenger?: IPassenger) => void;
  handle_change: (value: IPassenger) => void;
  handlePassengerDetailsSidebar: (passenger: IPassenger) => void;
  getPassengerLabel: (passenger: IPassenger) => string;
  createPassengerFromString: (lastName: string) => void;
  updatePassengerFormPhone: (phone: string) => void;
  updatePassengerFormCivility: (civility: string) => void;
  passengersAutocompletion: IPassenger[] | undefined;
  isInSidebar: boolean;
}

const PassengerCredentialsComponent: React.FC<Props> = (props) => {
  const {
    t,
    isPhoneNumberMandatory,
    autocompleteValue,
    setAutocompleteValue,
  } = usePassengerCredentials(props);

  const {
    getCivilitiesByDispatch,
    translateCivility,
  } = useCivilities();

  return (
    <>
      <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
        <FormControl className='passenger-credentials-civility' component="fieldset" disabled={!props.isInSidebar || !props.passenger.canEdit || !!props.passenger.id}>
          <Select
            placeholder={t('form.passengerCreate.civility')}
            value={props.passenger.civilityId}
            onChange={(e) => {
              props.updatePassengerFormCivility(e.target.value);
            }}
          >
            {getCivilitiesByDispatch('', props.passenger).map(civility => (
              <MenuItem value={civility.id}>{translateCivility(civility.label)}</MenuItem>
            ))}
          </Select>
        </FormControl>
        <div className={`input-passenger-details__field passenger-credentials-lastname ${props.isInSidebar ? '' : 'input-passenger-details-no-sidebar'}`}>
          <InputText
            label='input-passenger'
            id='input-passenger'
            prefix={UserLogo}
            class_name='text-passenger-name input-text__wide'
            name='passengers'
            placeholder={t('form.passenger.placeholder')}
            onChange={async function (e: React.ChangeEvent<HTMLInputElement>) {
              setAutocompleteValue(e.target.value);
              if (e.target.value !== '') {
                props.createPassengerFromString(e.target.value);
                props.handleGetPassenger(e.target.value);
              }
            }}
            value={autocompleteValue}
            onBlur={async function (e: React.ChangeEvent<HTMLInputElement>) {
              setTimeout(function () {
                props.handleGetPassenger(undefined);
              }, 300);
            }}
            suffix={(!props.passenger || !props.passenger.id) ? UserAddIcon : (props.passenger.canEdit) ? UserEditIcon : null}
            onSuffixClick={() => props.handleClickOnInputSuffix(props.passenger)}
            readonly={!props.isInSidebar}
          />
          {!props.isInSidebar && 
            <img
              className='icon__clickable'
              data-testid='edit-passenger'
              alt='logo'
              src={EditIcon}
              onClick={() => {
                props.handlePassengerDetailsSidebar(props.passenger)
              }}
            />
          }
          {props.passengersAutocompletion && (!props.passenger.id || autocompleteValue !== props.getPassengerLabel(props.passenger)) && (
            <div className={'passenger__dropdown'}>
              {Object.entries(props.passengersAutocompletion).map(
                ([key, value]: [string, IPassenger], index) => (
                  <div
                    className={
                      index % 2 == 0 ? 'passenger__item' : 'passenger__item item-bg-dark'
                    }
                    key={value.id}
                    onClick={() => {
                      props.handle_change(value);
                    }}
                  >
                    <PassengerItem passenger={value} />
                  </div>
                ),
              )}
            </div>
          )}
        </div>
      </Box>

      <div className='passenger-credentials'>
        <div className='field'>
          <div className='passenger-phone-title'>
            <div className='title'>
              <FormLabel label={t(`form.passengerCreate.phone`)}  mandatory={isPhoneNumberMandatory} />
            </div>
          </div>
          <InputPhone
            id='phone-passenger'
            value={props.passenger.phone}
            onChange={(value) => {
              if (!props.passenger.id) {
                props.updatePassengerFormPhone(value);
              }
            }}
            placeholder={t('form.passengerCreate.placeholderPhone')}
            disabled={!props.isInSidebar || !!props.passenger.id}
          />
        </div>
      </div>
      </>
  )

}

export default PassengerCredentialsComponent;