import React from 'react';
import {Button, Stack} from '@mui/material';
import { t } from 'i18next';
import EventBus from '../../../common/EventBus';
import IMission from '../../../types/mission.type';
import MissionViewComponent from '../../mission/MissionView.component';

interface Props {
  handleConfirm?: () => void;
  mission?: IMission;
  kindOfPage?: string;
}

const Confirmation: React.FC<Props> = (props) => {
  return (
    <div className='confirmation'>
      <h5 className='confirmation__title'>{t(`form.${props.kindOfPage}.confirm_title`)}</h5>
      <div className="confirmation__info">
        {props.mission && <MissionViewComponent mission={props.mission} />}
        <hr />
        <p className="confirmation__info-text body-small">
          <strong>{t('form.quotes.please')}</strong>
        </p>
        <p className="confirmation__info-text body-small">{t('form.quotes.confirmation')}</p>
      </div>
      <Stack direction="row" spacing={6} paddingTop={2} sx={{ margin: '0 4rem 2rem 4rem', justifyContent: "center" }}>
        <Button
          id='cancel'
          onClick={() => EventBus.dispatch('modalIsClose')}
          variant='outlined'
        >
          {t('form.action.cancel')}
        </Button>
        <Button
          id='confirm'
          type='submit'
        >
          {t(`form.${props.kindOfPage}.confirm`)}
        </Button>
      </Stack>
    </div>
  );
};

export default Confirmation;
