import axios from 'axios';
import { BookerDto } from '../dto/booker.dto';

class HabilitationApi {
   public static async getBookers(accessToken: string) : Promise<BookerDto[]> {
     return await axios.get<BookerDto[]>(`${process.env.REACT_APP_API_HABILITATIONS_URL}/v1/users/booker`, {
       headers: { Authorization: `Bearer ${accessToken}` },
     }).then((response) => {
       return response.data;
     })
   }
}

export default HabilitationApi;