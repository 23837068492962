import React from 'react';
import { MuiTelInput } from 'mui-tel-input'

interface InputPhoneProps {
  id?: string,
  value?: string;
  placeholder?: string;
  onChange: (value: string) => void;
  disabled?: boolean;
}

const InputPhone: React.FC<InputPhoneProps> = ({ id, value, placeholder, onChange, disabled }) => {

  return (
      <MuiTelInput
        id={id}
        data-testid={id}
        value={value}
        onChange={onChange}
        preferredCountries={['GB', 'FR', 'US']}
        placeholder={placeholder}
        disabled={disabled}
      />
  );
};

export default InputPhone;
