import React, { useState } from 'react';
import { t } from 'i18next';
import IMission from '../../../types/mission.type';
import MissionViewComponent from '../../mission/MissionView.component';
import { Button, Stack } from '@mui/material';

interface Props {
  quote: IMission;
  id: number;
  handleCancel?: (command_id: number) => void;
  handleRefuse?: (command_id: number, mission_id: number) => void;
  handleApprove?: (command_id: number, mission_id: number) => void;
}

const ValidationEstimate: React.FC<Props> = (props) => {
  const [displayPrice, setDisplayPrice] = useState(false);

  return (
    <div className='validation' data-testid='validation-estimate'>
      <h5 className='validation__title'>{t('form.action.quotation_is_ready')}</h5>
      <div className='validation__info'>
        <p className='validation__info-title'>{t('form.quotes.your_request')}</p>
        <MissionViewComponent mission={props.quote} />
        <hr className='validation__info-divider'></hr>
        <p className='validation__info-text body-small'>
          <strong>{t('form.quotes.approve')}</strong>
        </p>
        {displayPrice && (
          <p className='validation__info-text body-small'>
            {t('form.quotes.estimation_request')}
            <strong>{props.quote.price ? props.quote.price + '€' : ''}</strong>
          </p>
        )}
      </div>
      <hr className='validation__buttons-divider' />
      <Stack direction="row" spacing={6} paddingTop={2} sx={{ margin: '0 4rem 2rem 4rem', justifyContent: "center" }}>
        <Button
          id='cancel'
          data-testid='cancel'
          variant='outlined'
          onClick={() => {
            props.handleCancel && props.handleCancel(props.id);
          }}
        >
          {t('form.action.cancel')}
        </Button>
        <Button
          id='refuse'
          data-testid='refuse'
          className='red'
          onClick={() => {
            props.handleRefuse && props.handleRefuse(props.id, parseInt(props.quote.id ?? ''));
          }}
        >
          {t('form.action.refuse')}
        </Button>
        <Button
          id='confirm'
          data-testid='confirm'
          className='green'
          onClick={() => {
            props.handleApprove && props.handleApprove(props.id, parseInt(props.quote.id ?? ''));
          }}
        >
          {t('form.action.approve')}
        </Button>
      </Stack>
    </div>
  );
};

export default ValidationEstimate;
