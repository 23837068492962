import axios from 'axios';
import EventBus from '../../common/EventBus';
import IMission from '../../types/mission.type';
import camelcaseKeys from 'camelcase-keys';
import { formDataBuilder } from '../../utils/formDataBuilder';

const API_URL_MISSION = `${process.env.REACT_APP_API_MISSIONS_URL}/missions`;

export class ReservationService {
  public static async create(accessToken: string, mission: IMission) {
    const objectToFormDataConverter = formDataBuilder();
    const requestBody: FormData = objectToFormDataConverter(mission);

    return await axios
      .post(`${API_URL_MISSION}/request`, requestBody, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .then((response) => {
        if (response.status === 201) {
          return { message: 'Reservation créée' };
        }
      })
      .catch((error) => {
        const statusCode = error.response ? error.response.status : null;
        if (statusCode === 401) {
          EventBus.dispatch('logout');
        } else {
          throw error.response.data;
        }
      });
  }

  public static async update(accessToken: string, id: string, mission: IMission) {
    const objectToFormDataConverter = formDataBuilder();
    const requestBody: FormData = objectToFormDataConverter(mission);

    return await axios
      .post(`${API_URL_MISSION}/update/${id}`, requestBody, {
        headers: {
          'Content-Type': 'multipart/form-data',
          authorization: `Bearer ${accessToken}`,
        },
      })
      .then((response) => {
        if (response.status === 201) {
          return { message: 'Reservation modifié' };
        }
      })
      .catch((error) => {
        const statusCode = error.response ? error.response.status : null;
        if (statusCode === 401) {
          EventBus.dispatch('logout');
        } else {
          throw error.response.data;
        }
      });
  }

  public static async getMission(accessToken: string, mission_id: string | null) {
    if (!mission_id) {
      return null;
    }
    return await axios
      .get(`${API_URL_MISSION}/getMission?missionId=${mission_id}`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .then((response) => {
        return camelcaseKeys(response.data, { deep: true }) as IMission;
      })
      .catch((error) => {
        throw error;
      });
  }
}
