import axios from 'axios';
import { EventType, IPublicClientApplication } from '@azure/msal-browser';
import { b2cPolicies, loginRequest } from '../../../config/auth.config';
import EventBus from '../../EventBus';
import UserService from './user.service';

export class AuthService {
  public static async test(accessToken: string) {
    return axios
      .get(`${process.env.REACT_APP_API_HABILITATIONS_URL}/v1/auth/me/adb2c`, {
        headers: { Authorization: `Bearer ${accessToken}` },
      })
      .then((response) => { })
      .catch((error) => {
        const statusCode = error.response ? error.response.status : null;
        if (statusCode === 401) {
          EventBus.dispatch('logout');
        } else {
          console.log(error);
        }
      });
  }

  public static login(msalInstance: IPublicClientApplication) {

    if (msalInstance.getAllAccounts().length === 0) {
      msalInstance.loginRedirect(loginRequest);
    }
  }

  static logout(instance: IPublicClientApplication) {
    instance.logoutRedirect();
  }

  public static ssoSilentLogin(msalInstance: IPublicClientApplication) {
    const callbackId = msalInstance.addEventCallback((event) => {
      if (
        event.eventType === EventType.LOGIN_SUCCESS ||
        event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS
      ) {
        if (event.payload && 'account' in event.payload) {
          const account = event.payload.account;
          /**
           * For the purpose of setting an active account for UI update, we want to consider only the auth
           * response resulting from SUSI flow. "tfp" claim in the id token tells us the policy (NOTE: legacy
           * policies may use "acr" instead of "tfp"). To learn more about B2C tokens, visit:
           * https://docs.microsoft.com/en-us/azure/active-directory-b2c/tokens-overview
           */
          if (
            account?.idTokenClaims &&
            account.idTokenClaims['tfp'] === b2cPolicies.names.editProfile
          ) {
            // retrieve the account from initial sing-in to the app
            const originalSignInAccount = msalInstance
              .getAllAccounts()
              .find(
                (account) =>
                  account.idTokenClaims &&
                  account.idTokenClaims.oid === account.idTokenClaims?.oid &&
                  account.idTokenClaims.sub === account.idTokenClaims?.sub &&
                  account.idTokenClaims['tfp'] === b2cPolicies.names.signUpSignIn,
              );

            const signUpSignInFlowRequest = {
              authority: b2cPolicies.authorities.signUpSignIn.authority,
              account: originalSignInAccount,
            };

            // silently login again with the signUpSignIn policy
            msalInstance.ssoSilent(signUpSignInFlowRequest);
          }
        }
      }

      if (event.eventType === EventType.SSO_SILENT_SUCCESS) {
        if (event.payload && 'account' in event.payload) {
          EventBus.dispatch('ssoSilentSuccess');
        }
      }
    });

    return () => {
      if (callbackId) {
        msalInstance.removeEventCallback(callbackId);
      }
    };
  }
}

export default AuthService;
