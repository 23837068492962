import { Components } from '@mui/material/styles';
import variables from '../../scss/utils/_variables.module.scss';

export const muiPaperTheme: Components['MuiPaper'] = {
  styleOverrides: {
    root: {
      borderRadius: 0,
      boxShadow: '0px 8px 24px 0px rgba(51, 119, 255, 0.15)',
      border: `1px solid  ${variables.lighterBgColor}`,
    },
    elevation: {
      boxShadow: `1px 1px 13px ${variables.dark2}`,
      border: 0,
    }
  },
};