import { Components } from '@mui/material/styles';
import variables from '../../scss/utils/_variables.module.scss';

export const muiButtonTheme: Components['MuiButton'] = {
  styleOverrides: {
    root: {
      textTransform: 'uppercase',
      borderRadius: 0,
      color: variables.white,
      backgroundColor: variables.mainBgColor,
      fontFamily: `'EuclidCircularA-Semibold', -apple-system, BlinkMacSystemFont, sans-serif`,
      '&:hover': {
        backgroundColor: variables.lighterBgColor,
      },
      '&:active': {
        backgroundColor: variables.secondaryBgColor,
      },
      '&:focus': {
        border: `1px solid ${variables.infoLight}`,
        outline: 'none',
      },
      '&:disabled': {
        border: `1px solid ${variables.infoLight}`,
        backgroundColor: variables.lighterBgColor,
        color: variables.white,
        outline: 'none',
        opacity: '0.4'
      },
      '&.green': {
        backgroundColor: variables.successColor,
      },
      '&.red': {
        backgroundColor: variables.secondaryBgColor,
      },
    },
    endIcon: {
      '& path': {
        fill: variables.white
      },
    },
  },
  variants: [
    {
      props: { variant: 'outlined' },
      style: {
        border: `1px solid ${variables.mainBgColor}`,
        borderRadius: '0',
        color: variables.mainTextColor,
        backgroundColor: variables.white,
        fontWeight: '600',
        '& .MuiButton-endIcon path': {
          fill: variables.mainTextColor,
        },
        '&:hover': {
          borderColor: variables.lighterBgColor,
          color: variables.lighterBgColor,
          backgroundColor: variables.light4,
        },
        '&:active': {
          backgroundColor: variables.dark4,
        },
        '&:focus': {
          border: `1px solid ${variables.light3}`,
          outline: 'none',
        },
        '&:disabled': {
          border: `1px solid ${variables.mainBgColor}`,
          backgroundColor: variables.white,
          color: variables.mainTextColor,
          outline: 'none',
          opacity: '0.4'
        },
      },
    },
    {
      props: { variant: 'filter' },
      style: {
        border: `1px solid ${variables.light1}`,
        backgroundColor: 'transparent',
        borderRadius: '0',
        fontSize: '0.875rem',
        color: variables.blue4,
        height: 'inherit',
        '& .MuiButton-endIcon path': {
          fill: 'inherit',
        },
        '&:hover': {
          borderColor: variables.lighterBgColor,
          color: variables.lighterBgColor,
          backgroundColor: variables.light4,
        },
        '&:active': {
          backgroundColor: variables.dark4,
        },
        '&:focus': {
          border: `1px solid ${variables.light3}`,
          outline: 'none',
        },
        '&:disabled': {
          border: `1px solid ${variables.mainBgColor}`,
          backgroundColor: variables.white,
          color: variables.mainTextColor,
          outline: 'none',
          opacity: '0.4'
        },
      },
    },
    {
      props: { variant: 'addStep' },
      style: {
        border: 'none',
        background: 'none',
        fontSize: '0.875rem',
        color: variables.mainTextColor,
        '&:hover': {
          background: 'none',
        },
        '&:focus': {
          background: 'none',
        },
        '&:active': {
          background: 'none',
        },
      },
    },
    {
      props: { size: 'small' },
      style: {
        height: '2rem',
        fontSize: '0.875rem',
      },
    },
    {
      props: { size: 'medium' },
      style: {
        height: '2.375rem',
      },
    },
    {
      props: { size: 'large' },
      style: {
        fontSize: '1rem',
        fontWeight: '500',
        height: '3.188rem',
        paddingLeft: '1rem',
        paddingRight: '1rem',
      },
    },
  ],
};