import React, { useEffect, useState, useCallback } from 'react';
import errorService, { CustomError, ErrorType } from '../services/notification/error.service';
import i18n, { t } from "i18next";
import { Alert } from "@mui/material";

interface ErrorListProps {
  errorType: ErrorType;
}

const ErrorDisplay: React.FC<ErrorListProps> = ({ errorType }) => {
  const [errors, setErrors] = useState<CustomError[]>([]);
  const [errorMessage, setErrorMessage] = useState<string>('');

  const updateErrorsAndMessage = useCallback(() => {
    const currentErrors = errorService.getErrorsByType(errorType);
    setErrors(currentErrors);
    const newMessage = currentErrors.length > 0
      ? currentErrors.map((error) => `${t(error.message)}<br />`).join('')
      : '';
    setErrorMessage(newMessage);
  }, [errorType]);

  useEffect(() => {
    errorService.subscribe(updateErrorsAndMessage);

    return () => {
      errorService.unsubscribe(updateErrorsAndMessage);
    };
  }, [updateErrorsAndMessage]);

  useEffect(() => {
    i18n.on('languageChanged', updateErrorsAndMessage);

    return () => {
      i18n.off('languageChanged', updateErrorsAndMessage);
    };
  }, [updateErrorsAndMessage]);

  return (
    <>
      {errors.length > 0 && (
        <Alert severity="error" sx={{ width: '34.626rem', marginBottom: '1rem' }}>
          <div className='alert_html' dangerouslySetInnerHTML={{ __html: errorMessage }}></div>
        </Alert>
      )}
    </>
  );
};

export default ErrorDisplay;
