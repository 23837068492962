import React from 'react';
import IPassenger from '../../../../types/passenger.type';
import FormLabel from '../../../../components/form/form-label/FormLabel.component';
import { t } from 'i18next';
import AddRound from '../../../../assets/img/icon/AddRound.svg';
import PassengerAddressesFormItem from './PassengerAddressesFormItem.component';
import usePassengerAdressesForm from '../../hook/passenger-form/use-passenger-addresses-form.hook';

interface ChildProps {
  passenger_id?: number;
  submit?: (passenger: IPassenger) => void | undefined;
  onChange: (property: string, value: any) => void;
  disabled?: boolean;
}

const PassengerAddressesForm: React.FC<ChildProps> = (props: ChildProps) => {
  const {
    addresses,
    ADD_ADDRESS,
    HANDLE_CHANGE_ADDRESS,
    DELETE_ADDRESS,
  } = usePassengerAdressesForm(props);

  return (
    <>
      <h6>
        <FormLabel label={t(`form.passengerCreate.address.title`)} mandatory={false} />
      </h6>
      <div className='passenger-form__address'>
        {addresses && addresses.length > 0 && (
          <>
            {addresses.map((address, index) => (
              <PassengerAddressesFormItem
                key={'key' + index}
                address={address}
                index={index}
                disabled={props.disabled}
                update={(key, value) => {
                  HANDLE_CHANGE_ADDRESS(key, value, index);
                }}
                delete={() => {
                  DELETE_ADDRESS(index);
                }}
              />
            ))}
          </>
        )}
        {!props.disabled &&
          <div
            onClick={ADD_ADDRESS}
            id='btn-add-address'
            data-testid='btn-add-address'
            className='passenger-form__address__add'
          >
            <img src={AddRound} alt='' />
            <span className='body-normal-small text-uppercase'>
            {t(`form.passengerCreate.address.add`)}
          </span>
          </div>
        }
      </div>
    </>
  );
};
export default PassengerAddressesForm;
