import { createTheme } from '@mui/material';
import {muiButtonTheme} from "./muiButton.theme";
import {muiAlertTheme} from "./muiAlert.theme";
import {muiChipTheme} from "./muiChip.theme";
import {muiTypographyTheme} from "./muiTypography.theme";
import {muiPaperTheme} from "./muiPaper.theme";
import {muiFormGroupTheme} from "./muiFormGroup.theme";
import {muiAvatarTheme} from "./muiAvatar.theme";
import {muiOutlinedInputTheme} from "./muiOutlinedInput.theme";
import {muiInputBaseTheme} from "./muiInputBase.theme";
import {muiFormControl} from "./muiFormControl.theme";
import {muiSelectTheme} from "./muiSelect.theme";

export function useCspTheme() {
  return createTheme({
    components: {
      MuiButton: muiButtonTheme,
      MuiAlert: muiAlertTheme,
      MuiChip: muiChipTheme,
      MuiTypography: muiTypographyTheme,
      MuiPaper: muiPaperTheme,
      MuiFormGroup: muiFormGroupTheme,
      MuiAvatar: muiAvatarTheme,
      MuiInput: muiInputBaseTheme,
      MuiOutlinedInput: muiOutlinedInputTheme,
      MuiFormControl: muiFormControl,
      MuiSelect: muiSelectTheme,
    },
  });
}
