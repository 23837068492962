import IMission from "../../../types/mission.type";

function usePassengersCount(mission: IMission) {
    const passengersCount = () => {
        return mission.passengers.reduce((accumulator, passenger) => {
            return accumulator + (passenger.adults ?? 0) + (passenger.childrens ?? 0) + (passenger.infants ?? 0)
        }, 0);
    }

    return {
        passengersCount,
    };
}

export default usePassengersCount;