import React from 'react';
import IMission from '../../types/mission.type';
import ArrowRight from '../../assets/img/icon/arrowRight.svg';
import Pin from '../../assets/img/icon/Pin.svg';
import calendar from '../../assets/img/raw/calendar.svg';
import clock from '../../assets/img/raw/clock.svg';
import Delay from '../../assets/img/icon/Delay.svg';
import profile from '../../assets/img/icon/profile.svg';
import { transformDate, transformTime } from '../../helpers/TransformDate.helper';
import StatusButton from '../button/StatusButton.component';
import PassengerDetailsViewComponent from '../../domain/passenger/component/passenger-details/PassengerDetailsView.component';

export interface Props {
  notification: IMission;
  index: number;
}

const NotificationsItem = (props: Props) => {
  return (
    <div
      key={'notification-' + props.index}
      className='notifications__content__body__item'
      data-testid='notifications-item'
    >
      <div className='notifications__content__body__item__content'>
        <div className='notifications__content__body__item__content__title'>
          <span className='body-normal-small text-uppercase'>
            {props.notification.service?.type} :
          </span>
        </div>
        {props.notification.steps && (
          <div className='notifications__content__body__item__content__trip'>
            <div className='notifications__content__body__item__content__trip__steps flex-wrap'>
              {props.notification.service?.type === 'Transport' && <img src={Pin} alt='' />}
              {props.notification.steps.map((step, index) => (
                <>
                  {index > 0 && <img src={ArrowRight} alt='' />}
                  <span className='body-normal-small'>{step.address.value}</span>
                </>
              ))}
            </div>
          </div>
        )}
        <div className='notifications__content__body__item__content__date'>
          <div className='notifications__content__body__item__content__date__item'>
            <img src={calendar} alt='pickup-date' />
            <span>{transformDate(props.notification.pickupDate)}</span>
          </div>
          <div className='notifications__content__body__item__content__date__item'>
            <img src={clock} alt='pickup-time' />
            <span>{transformTime(props.notification.pickupTime)}</span>
          </div>
          {props.notification.service?.type === 'Transport' &&
            props.notification.dropoffTime &&
            props.notification.pickupTime && (
              <div className='notifications__content__body__item__content__date__item'>
                <img src={Delay} alt='duration' />
                <span>
                  {props.notification.dropoffTime &&
                    Math.ceil(
                      Math.abs(
                        new Date(props.notification.dropoffTime).getTime() -
                          new Date(props.notification.pickupTime).getTime(),
                      ) /
                        (1000 * 60 * 60),
                    ) + 'h'}
                </span>
              </div>
            )}
        </div>
        <div className='notifications__content__body__item__content__passenger'>
          <img src={profile} alt='main-passenger-icon' />
          <span>
            {props.notification.passengers ? props.notification.passengers[0]?.firstname ?? '' : ''}{' '}
            {props.notification.passengers
              ? props.notification.passengers[0]?.lastname?.toUpperCase()
              : ''}
          </span>
        </div>
        <div className='notifications__content__body__item__content__passengers'>
          {props.notification.passengers && (
            <PassengerDetailsViewComponent
              passager={props.notification.passengers[0]}
              light={true}
            />
          )}
        </div>
        <div>
          <StatusButton status={props.notification.status} />
        </div>
      </div>
      <hr className='notifications__content__body__item__divider' />
    </div>
  );
};

export default NotificationsItem;
