import { Components } from '@mui/material/styles';
import variables from '../../scss/utils/_variables.module.scss';

export const muiOutlinedInputTheme: Components['MuiOutlinedInput'] = {
  styleOverrides: {
    root: {
      borderRadius: 0,
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: variables.light1,  // Couleur par défaut de la bordure
      },
      '&:hover .MuiOutlinedInput-notchedOutline': {
        borderColor: variables.light1,  // Couleur de la bordure au survol (hover)
      },
      '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderColor: variables.dark1,  // Couleur de la bordure en focus
        borderRadius: '4px'
      },
      '&.Mui-disabled .MuiOutlinedInput-notchedOutline': {
        borderColor: variables.light1,  // Couleur de la bordure quand désactivé
        backgroundColor: 'transparent',  // Couleur de fond quand désactivé
      },
    },
    input: {
      color: variables.lighterTextColor,
      fontFamily: `'EuclidCircularA-Semibold', -apple-system, BlinkMacSystemFont, sans-serif`,
      fontSize: '14px',
      '&::placeholder': {
        color: variables.dark3,
        opacity: 1,
      },
      '&.Mui-disabled': {
        backgroundColor: variables.lightGrey,  // Couleur de fond pour le champ input désactivé
        color: variables.lighterTextColor,          // Couleur du texte pour l'input désactivé
        WebkitTextFillColor: 'rgba(0, 0, 0, 1)',
      },
    },
  },
};