import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import i18n from '../../../../i18n/config';
import IPassengerDetails from "../../../../types/passenger-details.type";

interface Props {
  passager?: IPassengerDetails;
  light?: boolean;
}

function usePassengerDetailsView(props: Props) {
  const { t } = useTranslation('ns1', { i18n });
  const [passagerDetails, setPassagerDetails] = useState({} as IPassengerDetails);
  
  useEffect(() => {
    if (props.passager) {
      setPassagerDetails(props.passager);
    }
  }, [props.passager]);

  return {
    t,
    passagerDetails,
  };
}

export default usePassengerDetailsView;