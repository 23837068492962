import React, { useEffect, useState } from 'react';
import NewRequest from '../../components/new-request/NewRequest.component';
import { t } from 'i18next';
import BookingBlock from '../../components/block/booking/Booking.block';
import {Button} from '@mui/material';
import usePermissions from '../../config/usePermission';
import { PERMISSIONS } from '../../types/permissions.enum';
import ErrorDisplay from "../../common/components/error-display.component";

type Props = {
  state_modal: boolean;
};

const Dashboard: React.FC<Props> = (props) => {
  const hasPermissionArrivalBoardRead = usePermissions(PERMISSIONS['ArrivalsBoard.Read']);

  const todaysarrivals = (): void => {
    window.open(`${process.env.REACT_APP_TODAYSARRIVALSURL}`, '_blank');
  };

  return (
    <div id='dashboard' data-testid='dashboard' className='dashboard-page'>
      <div className='d-flex justify-content-between'>
        <NewRequest />
        {hasPermissionArrivalBoardRead && (
          <Button
            id='arrivals'
            data-testid='arrivals'
            className='button-today-arrivals'
            onClick={todaysarrivals}
            variant='outlined'
            size='large'
            sx={{ width: '13.375rem' }}
          >
            {t('todaysArrivals')}
          </Button>
        )}
      </div>

      <ErrorDisplay errorType='missionList' />

      <h2>{t('pages.dashboard.bookings-title')}</h2>
      <BookingBlock />
    </div>
  );
};

export default Dashboard;
