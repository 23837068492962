import { useEffect, useState } from "react";
import { InputFile } from "../../../components/form/input-file/input-file.component";
import IMission from "../../../types/mission.type";
import i18n, { t } from "i18next";
import Attachment from "../../../types/attachment.type";
import AttachmentComponent from "./Attachment.component";
import Grid from "@mui/material/Unstable_Grid2";

type Props = {
    mission?: IMission | null;
    updateMission: (property: string, value: any) => void;
};

const MAX_NUMBER_OF_FILES = 10;

const Attachments: React.FC<Props> = (props) => {
    const [fileList, setFileList] = useState<(File|Attachment)[]>([]);
    const [errors, setErrors] = useState<string[]>([]);

    const handleFiles = (files: FileList) => {
        setErrors([]);
        Array.from(files).forEach((file) => {
            if (fileCanBeAddedToListAndSetErrorsIfNot(file)) {
                fileList.push(file);
            }
        })
        setFileList(Array.from(fileList));
        props.updateMission('attachments', [...fileList]);
    };

    const fileCanBeAddedToListAndSetErrorsIfNot = (file: File): boolean => {
        if (fileList.length >= MAX_NUMBER_OF_FILES) {
            errors.push(i18n.t('form.attachments.errors.invalidNumberOfFiles', {limit: MAX_NUMBER_OF_FILES}));
        }
        if (!hasValidMimeType(file)) {
            errors.push(i18n.t('form.attachments.errors.invalidMimeType', {fileName: file.name}));
        }
        if ((getTotalWeightOfList() + file.size) > (MAX_NUMBER_OF_FILES * 1024 * 1024)) {
            errors.push(i18n.t('form.attachments.errors.invalidTotalWeight', {limit: MAX_NUMBER_OF_FILES}));
        }

        setErrors(errors.reduce((accumulator: string[], error: string) => {
            if (!accumulator.includes(error)) {
                accumulator.push(error);
            }
            return accumulator;
        }, []));

        return errors.length === 0 && !fileList.find((fileItem) => fileItem.name === file.name);
    }

    const getTotalWeightOfList = (): number => {
         return fileList.reduce((total, item) => total + (item?.size ?? 0), 0);
    };

    const hasValidMimeType = (file: File): boolean => {
        return [
            'image/jpeg',
            'image/png',
            'application/pdf',
            'image/pjpeg',
        ].includes(file.type);
    }

    const handleRemove = (fileToRemove: File | Attachment) => {
        const filteredFileList = fileList.filter((file) => file.name !== fileToRemove.name);
        setFileList(filteredFileList);
        props.updateMission('attachments', filteredFileList);
        setErrors([]);
    }

    useEffect(() => {
        if (props.mission?.attachments) {
            setFileList([...props.mission.attachments]);
        }
    }, [props.mission]);

    return (
        <>
            <hr className="mission-form__divider" />
            <Grid container>
                <Grid xs={12} md={6}>
                    <h6>{t('form.attachments.title')}</h6>
                    <InputFile
                        multiple={true}
                        handleFiles={handleFiles}
                        accept=".jpeg,.jpg,.jpe,.jfif,.jif,.png,.pdf"
                    />
                    {errors.map((error: string) => (
                        <p className="attachment-error">{error}</p>
                    ))}
                </Grid>
                <Grid xs={12} md={6} container columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                    {fileList.map((file, index) => (
                        <AttachmentComponent key={(file?.name ?? '') + index} file={file} handleRemove={handleRemove} />
                    ))}
                </Grid>
            </Grid>
        </>
    );
};

export default Attachments;