import React from 'react';
import cross from '../../assets/img/icon/cross.svg';
import { Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';

interface SidebarProps {
  onClose: () => void;
  onValidate: () => void;
  children?: React.ReactNode;
}

const Sidebar: React.FC<SidebarProps> = (props) => {
  const { t } = useTranslation('ns1', { i18n });

  return (
    <div className='sidebar'>
      <div className='sidebar-header'>
        <img src={cross} alt='closs sidebar' className='icon__clickable' onClick={props.onClose} />
      </div>
      <div className='sidebar-custom-content'>{props.children}</div>
      <div className='sidebar-footer'>
        <Button
          id='sidebar-cancel-button'
          data-testid='sidebar-cancel-button'
          onClick={props.onClose}
          variant='outlined'
          size='large'
        >
          {t('form.sidebar.cancel')}
        </Button>
        <Button
          id='sidebar-done-button'
          data-testid='sidebar-done-button'
          onClick={props.onValidate}
          size='large'
        >
          {t('form.sidebar.done')}
        </Button>
      </div>
    </div>
  );
};

export default Sidebar;
