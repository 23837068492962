import React from 'react';
import { useSearchParams } from 'react-router-dom';
import {Trans, useTranslation} from 'react-i18next';
import i18n from '../../i18n/config';
import logoChabe from '../../assets/images/logo-text.png';
import errorBackground from '../../assets/images/error-bg.jpg';

const ErrorPage = () => {
  const [searchParams] = useSearchParams();
  const errorType = searchParams.get('type');
  const { t } = useTranslation('errors', { i18n });

  return (
    <div className="error-page">
      <div className="background_branding_container">
        <img className="background_background_image" src={errorBackground} alt="Illustration" aria-hidden="true" />
      </div>
      <div className="panel" id="panel">
        <div className="panel-container">
          <img src={logoChabe} />
          <h1>
            <Trans
              i18nKey={`${errorType}.title`}
              ns="errors"
              defaults={t('unknownError.title', { ns: 'errors' })}
            />
          </h1>
          <p>
            <Trans
              i18nKey={`${errorType}.message`}
              ns="errors"
              components={[<a href={`${window.location.origin}`} />]}
              defaults={t('unknownError.message', { ns: 'errors' })}
            />
          </p>
        </div>
      </div>
    </div>
  );
};

export default ErrorPage;
