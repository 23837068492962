import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import usePermissions from "../../../../config/usePermission";
import i18n from '../../../../i18n/config';
import IPassenger from "../../../../types/passenger.type";
import { PERMISSIONS } from "../../../../types/permissions.enum";

interface Props {
    passenger: IPassenger;
    handleGetPassenger: (value?: string) => void;
    handleClickOnInputSuffix: (passenger?: IPassenger) => void;
    handle_change: (value: IPassenger) => void;
    handlePassengerDetailsSidebar: (passenger: IPassenger) => void;
    getPassengerLabel: (passenger: IPassenger) => string;
    passengersAutocompletion: IPassenger[] | undefined;
    isInSidebar: boolean;
  }

function usePassengerCredentials(props: Props) {
  const { t } = useTranslation('ns1', { i18n });
  const isPhoneNumberMandatory = usePermissions(PERMISSIONS['Missions.PassengerPhoneMandatory']);

  const updatePassenger = (property: string, value: any) => {
    props.passenger[property] = value;
  }

  const [autocompleteValue, setAutocompleteValue] = useState<string|undefined>(undefined);

  useEffect(() => {
    setAutocompleteValue(props.getPassengerLabel(props.passenger));
  }, [props.passenger.lastname, props.passenger.firstname]);

  return {
    t,
    isPhoneNumberMandatory,
    autocompleteValue,
    updatePassenger,
    setAutocompleteValue,
  };
}

export default usePassengerCredentials;