import { ReactComponent as Call } from '../../assets/img/icon/call.svg';
import { Stack, Typography } from '@mui/material';
import React from 'react';


export interface PhoneLabelProps {
  phoneNumber?: string;
}
const PhoneLabelComponent = (props: PhoneLabelProps) => {
  return <Stack direction="row" spacing={1}>
    <Call />
    <Typography variant="body2" sx={{color: '#6B7588'}}>
      {props.phoneNumber}
    </Typography>
  </Stack>;
};

export default PhoneLabelComponent;