import { toast, TypeOptions } from 'react-toastify';

type ToastType = Extract<TypeOptions, 'info' | 'success' | 'error' | 'warning'>;

class ToastService {
  addToast(type: ToastType, message: string) {
    toast[type](message);
  }

  success(message: string) {
    this.addToast('success', message);
  }

  error(message: string) {
    this.addToast('error', message);
  }

  info(message: string) {
    this.addToast('info', message);
  }

  warning(message: string) {
    this.addToast('warning', message);
  }
}

const toastService = new ToastService();
export default toastService;
