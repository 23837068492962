export type ErrorType = 'missionList' | 'passengerSearch';

export interface CustomError {
  type: ErrorType;
  message: string;
  timestamp: Date;
}

class ErrorService {
  private errors: Map<ErrorType, CustomError[]> = new Map();
  private listeners: ((errors: Map<ErrorType, CustomError[]>) => void)[] = []; // Liste des callbacks

  constructor() {
    this.errors.set('missionList', []);
    this.errors.set('passengerSearch', []);
  }

  subscribe(listener: (errors: Map<ErrorType, CustomError[]>) => void): void {
    this.listeners.push(listener);
  }

  unsubscribe(listener: (errors: Map<ErrorType, CustomError[]>) => void): void {
    this.listeners = this.listeners.filter(l => l !== listener);
  }

  private notifyListeners(): void {
    for (const listener of this.listeners) {
      listener(this.errors);
    }
  }

  private errorExists(type: ErrorType, message: string): boolean {
    const errorsOfType = this.errors.get(type);
    if (!errorsOfType) return false;

    return errorsOfType.some((error) => error.message === message);
  }

  addError(type: ErrorType, message: string): void {
    if (this.errorExists(type, message)) {
      return;
    }

    const error: CustomError = { type, message, timestamp: new Date() };
    const errorsOfType = this.errors.get(type);
    errorsOfType?.push(error);
    this.notifyListeners();
  }

  getErrorsByType(type: ErrorType): CustomError[] {
    return this.errors.get(type) ?? [];
  }

  clearErrorsByType(type: ErrorType): void {
    this.errors.set(type, []);
    this.notifyListeners();
  }

  clearAllErrors(): void {
    for (const type of Array.from(this.errors.keys())) {
      this.errors.set(type, []);
    }
    this.notifyListeners();
  }
}

const errorService = new ErrorService();
export default errorService;
