import axios from 'axios';
import { UserType } from '../../../types/user.type';
import i18n from '../../../i18n/config';

class UserService {
  public static async getMe(accessToken: string) {
    return await axios
        .get<UserType>(`${process.env.REACT_APP_API_HABILITATIONS_URL}/v1/auth/me/adb2c`, {
          headers: { Authorization: `Bearer ${accessToken}` },
        })
        .then((response) => {
          if (i18n.language !== response.data.navigationLanguage) {
            UserService.changeDisplayedLanguage(response.data.navigationLanguage);
          }
          return response.data;
        })
        .catch((error) => {
          console.log(error);
          return undefined;
        });
  }

  public static async updateNavigationLanguage(accessToken: string, language: string) {
    return await axios
        .put<any>(`${process.env.REACT_APP_API_HABILITATIONS_URL}/v1/profile/language`, { language }, {
          headers: { Authorization: `Bearer ${accessToken}` },
        })
        .then((response) => {
          UserService.changeDisplayedLanguage(response.data.navigationLanguage);
          return response.data;
        })
        .catch((error) => {
          console.log(error);
        });
  }

  public static changeDisplayedLanguage(language: string) {
    i18n.changeLanguage(language).then((t) => {
      i18n.reloadResources();
    });
  }
}

export default UserService;
