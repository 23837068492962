import { IPublicClientApplication, InteractionRequiredAuthError } from '@azure/msal-browser';

export async function getAccessToken(instance: IPublicClientApplication): Promise<string | void> {
  const accessTokenRequest = {
    scopes: [`https://${process.env.REACT_APP_AZURE_B2C_TENANT}/api-missions/user_access`],
    account: instance.getActiveAccount()!,
  };

  try {
    const accessTokenResponse = await instance.acquireTokenSilent(accessTokenRequest);
    return accessTokenResponse.accessToken;
  } catch (error) {
    if (error instanceof InteractionRequiredAuthError) {
      return instance.acquireTokenRedirect(accessTokenRequest);
    } else {
      console.error("Erreur lors de l'acquisition du token:", error);
      throw error;
    }
  }
}
