import IAddress from './address.type';
import IPassengerDetails from './passenger-details.type';

export default interface IPassenger extends IPassengerDetails {
  wayniumPrsId?: number;
  wayniumId?: number;
  id?: number;
  listId?: string,
  lastname: string;
  civilityId?: string;
  civilityLabel?: string;
  firstname?: string;
  phone?: string;
  email?: string;
  details?: string;
  mobility?: boolean;
  addresses?: IAddress[];
  canEdit?: boolean;
}

export const passengerMandatory = {
  id: false,
  lastname: true,
  civilityId: false,
  firstname: false,
  phone: false,
  email: false,
  details: false,
  mobility: false,
  addresses: false,
  adults: false,
  childrens: false,
  childSeats: false,
  infants: false,
  babySeats: false,
  cabinBaggages: false,
  checkedBaggages: false,
  whellchairs: false,
};
