import React, { useEffect } from 'react';
import {Stack, Typography} from '@mui/material';
import StatusButton from '../../../components/button/StatusButton.component';
import IMission from '../../../types/mission.type';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';
import { MissionStatus } from '../../../types/mission-status.enum';
import { useRetrieveAllBooker } from '../hook/use-retrieve-all-booker.hook';
import { transformDateSimpleFormat } from '../../../helpers/TransformDate.helper';
import AttachmentComponent from '../../../common/components/attachments/Attachment.component';
import { useDownloadAttachment } from '../hook/use-download-attachment.hook';
import Attachment from '../../../types/attachment.type';

export interface MissionRowMissionDetailProps {
  mission: IMission;
  handlePopInQuotation: () => void;
}

const MissionRowMissionDetail = (props: MissionRowMissionDetailProps) => {
  const { t } = useTranslation('ns1', { i18n });
  const [creatorName, setCreatorName] = React.useState<string | undefined>(undefined);

  const { data } = useRetrieveAllBooker();
  const { downloadFile } = useDownloadAttachment();

  const onDownload = (attachment: Attachment) => {
    if (props.mission.id) {
      downloadFile(attachment, props.mission.id);
    }
  }

  useEffect(() => {
    if (data) {
      const creator = data.find((booker) => {
          if (booker.contactId) {
            return booker.contactId.toString() === props.mission.contactId
          }
      });
      if (creator) {
        setCreatorName((creator.firstName ?? '') + ' ' + (creator.lastName ?? ''));
      }
    }
  }, [data]);

  return <div className="mission-array-detail-container">
    <Typography variant="inputLabel">{t('components.booking.row.details-title')}</Typography>
    {props.mission.status === MissionStatus.QuotationAvailable ? (
      <StatusButton onClick={props.handlePopInQuotation} status={props.mission.status} />
    ) : (
      <StatusButton status={props.mission.status} />
    )}
    <Typography variant="body2" className="mission-array-detail-subtitle" color={'#385273'}
                marginTop={1}>{t('components.booking.row.details.createdAt') + ' ' + transformDateSimpleFormat(props.mission.createdAt)}</Typography>
    {creatorName &&
      <Typography variant="body2" className="mission-array-detail-subtitle" color={'#385273'}
                  marginTop={1}>{t('components.booking.row.details.by') + ' ' + creatorName}</Typography>
    }
    {props.mission.attachments?.map((attachment) => (typeof attachment === 'object' && 'id' in attachment) && (
      <AttachmentComponent key={attachment.id} file={attachment} download={onDownload} />
    ))}
  </div>;
};

export default MissionRowMissionDetail;