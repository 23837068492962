import { Components } from '@mui/material/styles';
import variables from '../../scss/utils/_variables.module.scss';

export const muiFormGroupTheme: Components['MuiFormGroup'] = {
  styleOverrides: {
    root: {
      borderRadius: 0,
      width: '100%',
      padding: '10px'
    }
  },
};