import axios from 'axios';
import IVehiculeType from '../../types/vehicleType.type';
import EventBus from '../../common/EventBus';

const API_GET_VEHICLES = `${process.env.REACT_APP_API_HABILITATIONS_URL}/v1/vehicles`;

export class VehicleService {
  public static async getAllVehicleTypes(token: string): Promise<IVehiculeType[]> {
    const headers = {
      authorization: `Bearer ${token}`,
    };
    try {
      const res = await axios.get(API_GET_VEHICLES, { headers });
      return res.data;
    } catch (error: any) {
      const statusCode = error.response ? error.response.status : null;
      if (statusCode === 401) {
        EventBus.dispatch('logout');
      }
      return [];
    }
  }

  public static async getVehicleType(token: string, wayniumId: string, dispatch: string): Promise<IVehiculeType|null> {
    const headers = {
      authorization: `Bearer ${token}`,
    };
    try {
      const params = new URLSearchParams();
      params.append('dispatch', dispatch);
      const res = await axios.get(`${API_GET_VEHICLES}/${wayniumId}`, { headers, params });
      return res.data;
    } catch (error: any) {
      const statusCode = error.response ? error.response.status : null;
      if (statusCode === 401) {
        EventBus.dispatch('logout');
      }
      return null;
    }
  }
}
