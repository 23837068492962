import { Components } from '@mui/material/styles';
import variables from '../../scss/utils/_variables.module.scss';

export const muiChipTheme: Components['MuiChip'] = {
  styleOverrides: {
    root: {
      height: '2rem',
      fontFamily: `'EuclidCircularA-Semibold', -apple-system, BlinkMacSystemFont, sans-serif`,
      textTransform: 'uppercase',
      fontSize: '0.75rem',
      backgroundColor: variables.blue2,
      color: variables.lighterTextColor,
      fontWeight: '500',
    },
  }
};