import React, { useEffect, useState } from 'react';
import InputText from '../form/inputs/InputText.component';
import AutocompletionService from '../../services/autocompletion/autocompletion.service';
import { getAccessToken } from '../../utils/getAccessToken';
import { useMsal } from '@azure/msal-react';
import IAddress from '../../types/address.type';
import work from '../../assets/img/icon/building.svg';
import home from '../../assets/img/icon/Home.svg';
import TimezoneService from '../../services/timezone/timezone.service';
import {useTranslation} from 'react-i18next';
import i18n from 'i18next';
import error from '../../assets/img/icon/error.svg';

interface AddressProps {
  id: string;
  class_name: string;
  label: string | null;
  name: string;
  placeholder: string;
  prefix: string | null;
  onDelete?: () => void;
  address: IAddress;
  update: (value: IAddress) => void;
  id_passenger?: number;
  disabled?: boolean;
}

const Address: React.FC<AddressProps> = (props) => {
  const { instance } = useMsal();
  const [inputValue, setInputValue]= useState<string>()
  const [places, setPlaces] = useState();
  const [prefix, setPrefix] = useState<string | null>(null);
  const [sufix, setSufix] = useState<string | null>(null);

  const handleGetAddress = async (value: string, id_passenger?: number) => {
    try {
      const token = await getAccessToken(instance);
      if (!token) {
        console.warn("No access token found");
        return;
      }
      const response = await AutocompletionService.getAddress(token, value, id_passenger);
      setPlaces(response.data);
    } catch (error) {
      console.error("Erreur lors de la récupération des adresses :", error);
    }
  };

  const handle_change = async (value: IAddress) => {
    if (!value.timezone) {
      try {
        const token = await getAccessToken(instance);
        if (!token) {
          console.warn("No access token found");
          return;
        }
        const response = await TimezoneService.getTimezone(token, value.place_id);
        value.timezone = response.data.value;
        value.location = response.data.location;
        props.update(value);
        setPlaces(undefined);
      } catch (error) {
        console.error("Erreur lors de la récupération du fuseau horaire :", error);
      }
    } else {
      props.update(value);
    }
  };


  let inputProps = {};
  if (props.onDelete) {
    inputProps = {
      onDelete: props.onDelete,
    };
  }

  const ICON_PREFIX = (enumValue: number) => {
    switch (enumValue) {
      case 0:
        return work;
      case 1:
        return home;
      default:
        return null;
    }
  };

  useEffect(() => {
    if (props.prefix) {
      setPrefix(props.prefix);
    }
  }, [props.prefix]);

  useEffect(() => {
    if (props.address) {
      setInputValue(props.address.value);
    }
  }, [props.address]);

  useEffect(() => {
    if (props.address) {
      if (!props.prefix) {
        setPrefix(ICON_PREFIX(props.address.type ?? -1));
        setSufix(null);
      } else {
        setSufix(ICON_PREFIX(props.address.type ?? -1));
      }
    }
  }, [props.address.type]);

  const { t } = useTranslation('ns1', { i18n });

  return (
    <div className='address__container' key={props.id}>
      <InputText
        label={props.label}
        name={props.name}
        disabled={props.disabled}
        onChange={async function (e: React.ChangeEvent<HTMLInputElement>) {
          setInputValue(e.currentTarget.value)
          await handleGetAddress(e.currentTarget.value, props.id_passenger);
        }}
        id={props.id}
        class_name={'address__input ' + props.class_name}
        placeholder={props.placeholder}
        {...inputProps}
        prefix={prefix}
        suffix={sufix}
        value={inputValue}
        data-testid={props.id}
        onBlur={async function (e: React.ChangeEvent<HTMLInputElement>) {
          setInputValue(props.address.value)
          setTimeout(function () {
            setPlaces(undefined);
          }, 300);
        }}
      />
      {places && (
        <div className='address__dropdown' data-testid='address__dropdown'>
          {Object.entries(places).map(([key, value]: [string, any], index) => (
            <div
              className='address__item'
              data-testid='address__item'
              key={index}
              onClick={() => {
                handle_change(value);
              }}
            >
              {value.type === 0 || value.type === 1 ? (
                <img className='address__prefix' src={ICON_PREFIX(value.type) ?? ''} />
              ) : props.prefix ? (
                <img className='address__prefix' src={props.prefix} />
              ) : null}

              {value.title && (
                <span className='body-small'>{value.title.toUpperCase() + ' - '}</span>
              )}
              {value.value}
            </div>
          ))}
          {(Object.keys(places).length === 0) && (
            <div
              className='address__item'
              data-testid='address__item'
            >
              <img src={error} /> {t(`form.trip.no-result`)}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Address;
