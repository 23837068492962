import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import i18n from '../../../../i18n/config';
import IPassenger from "../../../../types/passenger.type";

interface Props {
    passenger: IPassenger;
    handleGetPassenger: (value?: string) => void;
    handleSidebarPassengerFormOpen: (passenger?: IPassenger) => void;
    handle_change: (value: IPassenger) => void;
    handlePassengerDetailsSidebar: (passenger: IPassenger) => void;
    getPassengerLabel: (passenger: IPassenger) => string;
    passengersAutocompletion: IPassenger[] | undefined;
  }

function usePassengerDetailsForm(props: Props) {
  const { t } = useTranslation('ns1', { i18n });
  const [passengerDetails, setPassengerDetails] = useState({} as IPassenger);
  const [maxChildSeats, setMaxChildSeats] = useState(0);
  const [maxBabySeats, setMaxBabySeats] = useState(0);

  useEffect(() => {
    if (props.passenger) {
        setPassengerDetails(props.passenger);
      if (props.passenger.childrens) {
        setMaxChildSeats(props.passenger.childrens);
      }
      if (props.passenger.infants) {
        setMaxBabySeats(props.passenger.infants);
      }
    }
  }, [props.passenger]);

  const updatePassenger = (property: string, value: string | number | boolean) => {
    props.passenger[property] = value;
  }

  return {
    t,
    passengerDetails,
    maxChildSeats,
    maxBabySeats,
    updatePassenger,
    setMaxChildSeats,
    setMaxBabySeats,
  };
}

export default usePassengerDetailsForm;