import IError from '../types/error.type';
import { t } from 'i18next';
import information from '../assets/img/icon/information.svg';
import error from '../assets/img/icon/error.svg';

export const errorsToMessage = (errors: IError) => {
  let errorsMessage = errorsTechnicalToMessage(errors) + errorsMandatoryToMessage(errors);
  for (const [key, value] of Object.entries(errors)) {
    if (value && value !== 'mandatory' && key != 'technical') {
      errorsMessage +=
        '<span class="title"><img src="' +
        information +
        '" style="width: 1rem; height: 1rem"/>' +
        t(`errors.${key}.${value}`, key) +
        '</span>';
    }
  }
  errorsMessage += '</ul>';
  return errorsMessage;
};

export const errorsMandatoryToMessage = (errors: IError) => {
  if (!Object.values(errors).includes('mandatory')) {
    return '';
  }
  let errorsMessage = '<span class="title">' + t(`errors.title.mandatory`) + '</span>';
  errorsMessage += '<ul class="error-container">';
  for (const [key, value] of Object.entries(errors)) {
    if (value && value === 'mandatory') {
      errorsMessage += '<li class="error">' + t(`errors.${key}.${value}`) + '</li>';
    }
  }
  errorsMessage += '</ul>';
  return errorsMessage;
};

export const errorsTechnicalToMessage = (errors: IError) => {
  if (!errors.technical) {
    return '';
  }
  return (
    '<span class="title"><img src="' +
    error +
    '" />' +
    t(`errors.technical`) +
    ' ' +
    errors.technical +
    '</span>'
  );
};
