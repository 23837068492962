import { useMsal } from '@azure/msal-react';
import { t } from 'i18next';
import React, { useState, useEffect } from 'react';
import { Avatar } from '@mui/material';
import colors from '../../../scss/utils/_variables.module.scss';
import person from '../../../assets/images/user.svg';
import {ReactComponent as LogoutIcon} from '../../../assets/images/logout.svg';
import AuthService from '../../../common/services/user/auth.service';
import { Button } from '@mui/material';
import useFetchUser from "../../../domain/user/hook/use-fetch-user.hook";

const AccountMenu: React.FC = () => {
  const { instance } = useMsal();
  const [hover, setHover] = useState<boolean>(false);
  const [openMenu, setOpenMenu] = useState<boolean>(false);

  const { user, userApi, loading, error } = useFetchUser(instance);


  const handleLogout = () => {
    AuthService.logout(instance);
  };

  const translateToUpper = (key: string) => {
    const translation = t(key);
    return translation ? translation.toLocaleUpperCase() : '';
  };

  return (
    <div className='account-menu' data-testid='account-menu'>
      <Avatar
        id='btn-account-menu'
        data-testid='btn-account-menu'
        className='account-avatar'
        onClick={() => setOpenMenu(!openMenu)}
        onMouseOver={() => {
          setHover(true);
        }}
        onMouseLeave={() => {
          setHover(false);
        }}
        sx={{
          textTransform: 'uppercase',
          bgcolor: hover ? colors?.mainBgColor : colors.lightGrey,
          color: hover ? colors?.lightGrey : colors.mainBgColor,
          fontFamily: 'EuclidCircularA-Semibold',
          fontSize: '18px',
          width: '48px',
          height: '48px',
          cursor: 'pointer',
        }}
      >
        {user &&
          user.idTokenClaims &&
          user.idTokenClaims.given_name !== undefined &&
          user.idTokenClaims.given_name !== null &&
          user.idTokenClaims.family_name !== undefined &&
          user.idTokenClaims.family_name !== null &&
          (user?.idTokenClaims as any)?.given_name?.charAt(0) +
            (user?.idTokenClaims as any)?.family_name?.charAt(0)}
      </Avatar>
      {openMenu && (
        <div className='account-menu__box' data-testid='account-menu-box'>
          <div>
            <div className='account-menu__triangle'></div>
          </div>
          <div className='account-menu__title'>
            <img className='' src={person} alt='person_icon' />
            <span className='body-medium-small'>
              {translateToUpper('components.accountMenu.title')}
            </span>
          </div>
          <div className='account-menu__content'>
            <div className='account-menu__content__item d-flex'>
              <Avatar
                className='account-avatar'
                sx={{
                  textTransform: 'uppercase',
                  bgcolor: colors?.lightGrey,
                  color: colors?.mainBgColor,
                  fontFamily: 'EuclidCircularA-Semibold',
                  fontSize: '18px',
                  width: '48px',
                  height: '48px',
                }}
              >
                {user &&
                  user.idTokenClaims &&
                  user.idTokenClaims.given_name &&
                  (user.idTokenClaims as any)?.given_name?.charAt(0) +
                    (user.idTokenClaims.family_name
                      ? (user?.idTokenClaims as any)?.family_name?.charAt(0)
                      : '')}
              </Avatar>
              <div className='d-flex flex-column'>
                <span className='body-medium'>
                  {(user?.idTokenClaims as any)?.given_name}{' '}
                  {(user?.idTokenClaims as any)?.family_name}
                </span>
                <span className='body-medium-small'>{userApi?.email}</span>
              </div>
            </div>
            <div className='account-menu__content'>
              <Button
                id='account-menu-logout'
                data-testid='btn-logout'
                onClick={handleLogout}
                startIcon={<LogoutIcon />}
                size='small'
              >
                {t('components.accountMenu.logout')}
              </Button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AccountMenu;
