import { Components } from '@mui/material/styles';
import variables from '../../scss/utils/_variables.module.scss';

export const muiTypographyTheme: Components['MuiTypography'] = {
  styleOverrides: {
    root: {
      fontFamily: `'EuclidCircularA-Regular', -apple-system, BlinkMacSystemFont, sans-serif`,
      color: variables.mainTextColor,
    },
  },
  variants: [
    {
      props: { variant: 'tableHeader' },
      style: {
        fontFamily: `'EuclidCircularA-Semibold', -apple-system, BlinkMacSystemFont, sans-serif`,
        fontSize: '0.875rem',
        textTransform: 'uppercase',
        color: variables.mainTextColor
      },
    },
    {
      props: { variant: 'smallTitle' },
      style: {

        textTransform: 'uppercase',
        fontSize: '0.875rem',
      },
    },
    {
      props: { variant: 'inputLabel' },
      style: {
        textTransform: 'uppercase',
        fontSize: '0.875rem',
        color: variables.dark2
      },
    },
    {
      props: { variant: 'bodyModalSubTitle' },
      style: {
        fontFamily: `'EuclidCircularA-Semibold', -apple-system, BlinkMacSystemFont, sans-serif`,
        fontWeight: '700',
        fontSize: '1rem',
        color: variables.mainTextColor
      },
    },
  ],
};