function useLeadTime(user: any) {
    const leadTime = () => {
        return user?.account?.leadTime ?? 12
    }

    return {
        leadTime,
    };
}

export default useLeadTime;