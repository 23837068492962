import UserService from '../common/services/user/user.service';
import { useMsal } from '@azure/msal-react';
import { getAccessToken } from '../utils/getAccessToken';
import { PERMISSIONS } from '../types/permissions.enum';
import { useQuery } from '@tanstack/react-query';

const usePermissions = (permissionName: PERMISSIONS) => {
  const { instance } = useMsal();

  const hasPermissionQuery = async () => {
    const accessToken = await getAccessToken(instance);
    if (!accessToken) {
      console.warn("No access token found");
      return;
    }
    const user = await UserService.getMe(accessToken);
    const rights = user?.rights;
    return rights?.includes(PERMISSIONS[permissionName]);
  };

  const { data } = useQuery({
    queryKey: ['user', 'me', 'permission', permissionName],
    queryFn: hasPermissionQuery,
    initialData: undefined,
    enabled: !!permissionName,
  });

  return data;
};

export default usePermissions;
