import React, { useEffect, useState } from 'react';
import IPassenger from '../../types/passenger.type';
import { Avatar } from '@mui/material';
import colors from '../../scss/utils/_variables.module.scss';
import useCivilities from '../../domain/passenger/hook/use-civilities.hook';
import getDispatch from '../../config/userDispatch';

interface Props {
  passenger: IPassenger;
  bg_color?: string;
  active?: boolean;
}

const PassengerItem: React.FC<Props> = (props) => {
  const [passenger, setPassenger] = useState({} as IPassenger);
  const { translateCivilityByIdAndDispatch } = useCivilities();
  const dispatch = getDispatch();

  // == Hooks ===============================================================

  useEffect(() => {
    setPassenger(props.passenger);
  }, [props.passenger]);

  return (
    <div
      className={
        'passenger-item ' + props.bg_color + (props.active ? ' passenger-item__active' : '')
      }
    >
      <Avatar
        sx={{
          textTransform: 'uppercase',
          bgcolor: colors?.primaryColor,
          color: colors?.lightGrey,
          fontFamily: 'EuclidCircularA-Semibold',
          fontSize: '14px',
        }}
      >
        {passenger && passenger.firstname?.charAt(0) + passenger.lastname?.charAt(0)}
      </Avatar>
      <div className='passenger-item__infos'>
        <span className='body-medium-small passenger-item__infos__name'>
          {dispatch &&
          passenger.civilityId &&
          (translateCivilityByIdAndDispatch(passenger.civilityId, dispatch)?.concat(' ') ?? '')}
          {passenger.lastname} {passenger.firstname}
        </span>
        <span className='body-extra-small passenger-item__infos__email'>{passenger.email}</span>
      </div>
    </div>
  );
};

export default PassengerItem;
