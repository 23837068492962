import axios, { AxiosResponse } from "axios";

export class MissionApi {
    public static async getAttachment(attachmentId: string, missionId: string, accessToken: string): Promise<AxiosResponse> {
        const headers = {
            authorization: `Bearer ${accessToken}`,
          };
          const params = {
            missionId,
            attachmentId,
          };
          return await axios.get(`${process.env.REACT_APP_API_MISSIONS_URL}/missions/get-mission-attachment`, {
             headers,
             params,
             responseType: 'blob',
          });
    }
}