import React from 'react';
import { Divider, Stack, Typography } from '@mui/material';
import IStep from '../../../types/step.type';
import { ReactComponent as Home } from '../../../assets/img/icon/Home.svg';
import { ReactComponent as Pin } from '../../../assets/img/icon/Pin.svg';
import { ReactComponent as Arrival } from '../../../assets/img/icon/ARRIVAL.svg';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';

export interface MissionRowAddressDetailProps {
  steps: IStep[];
}

const MissionRowAddressDetail = (props: MissionRowAddressDetailProps) => {
  const { t } = useTranslation('ns1', { i18n });

  return (<Stack direction="row" spacing={1} width="100%" height="100%" justifyContent="space-between">
    <div className="mission-array-detail-container">
      <Typography variant="inputLabel">
        {t('components.booking.row.address-title')}
      </Typography>
      {props.steps &&
        <Stack direction="column" spacing={1} marginTop={2}>
          {props.steps.map((step, index) => {
            return <>
              <Stack direction="row" spacing={1} key={index}>
                {index === 0 && <Home />}
                {index === props.steps.length - 1 && <Arrival />}
                {index > 0 && index != props.steps.length - 1 && <Pin />}
                <Typography variant="body2" sx={{color: '#3A3A3C'}}>
                  {step.address.title ?? step.address.value}
                </Typography>
              </Stack>
              { step.address.title && (
                  <Typography variant="body2" sx={{color: '#385273'}}>
                    {step.address.value}
                  </Typography>
                )
              }
            </>;
          })}
        </Stack>
      }
    </div>
    <Divider orientation="vertical" flexItem />
  </Stack>);
};

export default MissionRowAddressDetail;