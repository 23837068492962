import { Components } from '@mui/material/styles';
import variables from '../../scss/utils/_variables.module.scss';

export const muiSelectTheme: Components['MuiSelect'] = {
    styleOverrides: {
        root: {
            height: '3.438rem',
            width: '100%',
            backgroundColor: 'transparent',
            color: variables.dark2,
        },
    },
};