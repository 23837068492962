import { DropdownElement } from '../../../components/dropdown/Dropdown.component';
import i18n from '../../../i18n/config';
import { useState, useEffect } from 'react';
import UserService from '../../../common/services/user/user.service';
import { getAccessToken } from '../../../utils/getAccessToken';
import { useMsal } from '@azure/msal-react';

function useChangeLanguage() {
  const [selectedLanguage, setSelectedLanguage] = useState<DropdownElement | null>(null);
  const [languages, setLanguages] = useState<DropdownElement[]>([]);
  const { instance } = useMsal();

  useEffect(() => {
    const languageOptions: DropdownElement[] = [
      { label: 'FR', value: 'fr' },
      { label: 'EN', value: 'en' },
    ];
    setLanguages(languageOptions);
    const currentLanguage =
      languageOptions.find((item) => item.value === i18n.language) || ({} as DropdownElement);
    setSelectedLanguage(currentLanguage);
  }, []);

  useEffect(() => {
    if (languages.length > 0) {
      const currentLanguage = languages.find((item) => item.value === i18n.language) || ({} as DropdownElement);
      setSelectedLanguage(currentLanguage);
    }
  }, [i18n.language])

  const handleLanguageSelection = (selectedOption: DropdownElement | null) => {
    if (selectedOption) {
      setSelectedLanguage(selectedOption);
      getAccessToken(instance).then((token) => {
        if (!token) {
          console.warn("No access token found");
          return;
        }
        UserService.updateNavigationLanguage(token, selectedOption.value);
      })
    }
  };

  return { selectedLanguage, languages, handleLanguageSelection };
}

export default useChangeLanguage;