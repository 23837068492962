import { useMsal } from "@azure/msal-react";
import { getAccessToken } from "../../../utils/getAccessToken";
import { MissionApi } from "../api/mission.api";
import Attachment from "../../../types/attachment.type";

export function useDownloadAttachment() {
    const { instance } = useMsal();

    const downloadFile = async (attachment: Attachment, missionId: string) => {
        if (attachment.name) {
            try {
                const accessToken = await getAccessToken(instance);
                if (!accessToken) {
                    console.warn("No access token found");
                    return;
                }
                const attachmentResponse = await MissionApi.getAttachment(attachment.id, missionId, accessToken);
                const url = window.URL.createObjectURL(new Blob([attachmentResponse.data]));
                const link = document.createElement('a');
                link.href = url;
                link.download = attachment.name ?? '';
                document.body.appendChild(link);
                link.click();
                link.parentNode?.removeChild(link);
            } catch (error) {
                console.error("Error downloading attachment :", error);
            }
        }
    };


    return { downloadFile };
  }