import { useMsal } from "@azure/msal-react";
import { useState, useEffect } from "react";
import EventBus from "../../../../common/EventBus";
import PassengerService from "../../../../services/passengers/passenger.service";
import IAddress from "../../../../types/address.type";
import IPassengerErrors from "../../../../types/passenger.error.type";
import IPassenger from "../../../../types/passenger.type";
import { getAccessToken } from "../../../../utils/getAccessToken";

interface ChildProps {
    passenger_id?: number;
    submit?: (passenger: IPassenger) => void | undefined;
    onChange: (property: string, value: any) => void;
  }

function usePassengerAdressesForm(props: ChildProps) {
  const { instance } = useMsal();
  const [errors, setErrors] = useState({} as IPassengerErrors);
  const [addresses, setAddresses] = useState([] as IAddress[]);

  const DELETE_ADDRESS = (index: number) => {
    const newAddresses = JSON.parse(JSON.stringify(addresses));
    newAddresses.splice(index, 1);
    setAddresses(newAddresses);
    props.onChange('addresses', newAddresses);
  };

  const HANDLE_CHANGE_ADDRESS = (property: string, value: any, index: number) => {
    const newAddresses = JSON.parse(JSON.stringify(addresses));
    if(property === 'address'){
      newAddresses[index].value = value.value;
      newAddresses[index].timezone = value.timezone;
      newAddresses[index].location = value.location;
    } else {
      newAddresses[index][property] = value;
    }
    setAddresses(newAddresses);
    props.onChange('addresses', newAddresses);
  };

  const GET_ADDRESSES = async () => {
    if (!props.passenger_id) {
      setAddresses([]);
      return;
    }
    try {
      const token = await getAccessToken(instance);
      if (!token) {
        console.warn("No access token found");
        return;
      }
      const response = await PassengerService.getPassengerAddress(token, props.passenger_id ?? 0);
      setAddresses(response);
      props.onChange('addresses', response);
    } catch (error: any) {
      if (!error.errors && error.message) {
        setErrors({ technical: error.message });
      } else {
        setErrors(error.errors);
      }
      EventBus.dispatch('notLoading');
    }
  };

  const ADD_ADDRESS = () => {
    const newAddresses = JSON.parse(JSON.stringify(addresses));
    newAddresses.push({
      title: '',
      type: undefined,
      value: '',
      id_passager: props.passenger_id,
    } as IAddress);
    setAddresses(newAddresses);
  };

  useEffect(() => {
    GET_ADDRESSES();
  }, [props.passenger_id]);

  return {
    addresses,
    ADD_ADDRESS,
    HANDLE_CHANGE_ADDRESS,
    DELETE_ADDRESS,
  }
}

export default usePassengerAdressesForm;