import React, { useEffect, useState } from 'react';
import IMission, { missionMandatory } from '../../../types/mission.type';
import { t } from 'i18next';
import FormLabel from '../../form/form-label/FormLabel.component';
import InputTextField from "../../../common/components/input-text-field.component";

interface Props {
  id: string;
  class_name: string;
  label: string | null;
  name: string;
  mission?: IMission;
  onChange: (value: string) => void;
  onDelete?: () => void;
}

const MoreInformation: React.FC<Props> = (props) => {
  const [description, setDescription] = useState('');

  useEffect(() => {
    if (props.mission) {
      setDescription(props.mission.description);
    }
  }, [props.mission]);

  return (
    <div className='more-information' data-testid="more-information">
      {props.label && (
        <h6 className='time__title'>
          <FormLabel
            htmlFor='detail'
            label={props.label}
            mandatory={missionMandatory.externalReference}
          />
        </h6>
      )}
      <InputTextField
        id={props.id}
        rows={4}
        initialValue={description}
        onChange={props.onChange}
        placeholder={t('estimate.textarea')}
      />
    </div>
  );
};

export default MoreInformation;
