import { useEffect, useRef, useState } from "react";
import EventBus from "../../../common/EventBus";
import IPassenger from "../../../types/passenger.type";
import { useMsal } from "@azure/msal-react";
import IMission from "../../../types/mission.type";
import { getAccessToken } from "../../../utils/getAccessToken";
import PassengerService from "../../../services/passengers/passenger.service";

interface Props {
  mission: IMission;
  updateMission: (property: string, value: any) => void;
}

function usePassenger(props: Props) {
  const [passengers, setPassengers] = useState<IPassenger[]>([]);
  const [passengerForm, setPassengerForm] = useState<IPassenger>({} as IPassenger);
  
  const [isSidebarOpen, setIsSidebarOpen] = useState<boolean>(false);
  const [isMainPassengerFormOpened, setIsMainPassengerFormOpened] = useState<boolean>(false);
  const [passengersAutocompletion, setPassengersAutocompletion] = useState<
    IPassenger[] | undefined
  >(undefined);

  const passengerFormRef = useRef<{ TRY_FORM_SUBMIT: () => Promise<boolean> }>();
  const { instance } = useMsal();

  const getInitialPassengerForm = (): IPassenger => {
    return {
      listId: 'id-' + Date.now() + '-' + Math.floor(Math.random() * 1000),
      civilityId: '',
      adults: 1,
      lastname: '',
      firstname: '',
      phone: '',
      email: '',
      details: '',
      mobility: false,
      canEdit: true
    } as IPassenger;
  }

  const handleOpenMainPassengerForm = (passenger?: IPassenger) => {
    setPassengerForm(passenger ?? getInitialPassengerForm());
    setIsMainPassengerFormOpened(true);
    setIsSidebarOpen(true);
  }

  const resetPassenger = (passenger: IPassenger) => {
    const idx = passengers.findIndex((passengerElement) => isSamePassenger(passenger, passengerElement));

    if (passengers[idx].wayniumPrsId) {
      passengers[idx].delete = true;
      props.updateMission('passengers', passengers);
    } else {
      const filteredPassengers = [...passengers.filter((passengerElement) => !isSamePassenger(passenger, passengerElement))];
      props.updateMission('passengers', filteredPassengers);
    }
  };

  const submitMainPassengerForm = async (passenger: IPassenger) => {
    try {
      const token = await getAccessToken(instance);
      if (!token) {
        console.warn("No access token found");
        return;
      }
      await PassengerService.createOrUpdate(token, passenger);
    } catch (error) {
      console.error("Error retrieving token or updating passenger :", error);
    }
    updateMissionWithPassenger(passenger);
    setPassengerForm(passenger);
    setIsMainPassengerFormOpened(false);
  }

  const updateMissionWithPassenger = (passengerObject: IPassenger) => {
    if (isPassengerObjectEmpty(passengerObject)) {
      return;
    }

    const indexOfExistingPassenger = passengers.findIndex((passenger: IPassenger) => isSamePassenger(passengerObject, passenger));
    if (-1 === indexOfExistingPassenger) {
      passengers.push(passengerObject);
    } else {
      passengers[indexOfExistingPassenger] = {...passengerObject};
    }
    
    props.updateMission('passengers', [...passengers]);
  }

  const isPassengerObjectEmpty = (passengerObject: IPassenger): boolean => {
    return !passengerObject.id && passengerObject.lastname === '';
  }

  const isSamePassenger = (passengerToFind: IPassenger, passenger: IPassenger): boolean => {
    return (passengerToFind.id && passengerToFind.id === passenger.id)
    || (passengerToFind.listId && passengerToFind.listId === passenger.listId)
    || (!passengerToFind.id && !passenger.id && passengerToFind.lastname === passenger.lastname);
  }

  const handleGetPassengerFromAutocomplete = async (value?: string) => {
    if (value === undefined) {
      setPassengersAutocompletion(undefined);
    } else {
      try {
        const token = await getAccessToken(instance);
        if (!token) {
          console.warn("No access token found");
          return;
        }
        const response = await PassengerService.getPassengers(token, value);
        setPassengersAutocompletion(response as IPassenger[]);
      } catch (error) {
        console.error("Error retrieving token or passengers :", error);
      }
    }
  };

  const handleSelectPassengerFromAutocomplete = (passenger: IPassenger) => {
    if (!passenger.adults || passenger.adults === 0) {
      passenger.adults = 1;
    }
    setPassengersAutocompletion(undefined);
    setPassengerForm(passenger);
  }

  const getPassengerLabel = (passenger: IPassenger): string => {
    return `${passenger.firstname ?? ''}${
      passenger.firstname && passenger.lastname ? ' ' : ''
    }${passenger.lastname ?? ''}`;
  }

  const handleSidebarValidate = async () => {
    if (!isMainPassengerFormOpened) {
      updateMissionWithPassenger(passengerForm);
      setIsSidebarOpen(false);
    } else if (passengerFormRef.current && await passengerFormRef.current.TRY_FORM_SUBMIT()) {
      handleCancelMainPassengerForm();
    }
  };

  const handleSidebarToggle = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const handleClickOnAddPassengerButton = () => {
    const newPassenger = getInitialPassengerForm();
    setPassengerForm(newPassenger);
    handleOpenPassengerDetailsForm(newPassenger);
  }

  const handleCancelMainPassengerForm = () => {
    setIsMainPassengerFormOpened(false);
  }

  const handleOpenPassengerDetailsForm = (passenger?: IPassenger) => {
    setPassengerForm(passenger ?? getInitialPassengerForm());
    setIsMainPassengerFormOpened(false);
    setIsSidebarOpen(true);
  }

  const createPassengerFromString = (lastName: string) => {
    const passenger = getInitialPassengerForm();
    passenger.lastname = lastName;
    passenger.phone = passengerForm.phone;
    passenger.civilityId = passengerForm.civilityId;
    passenger.listId = passengerForm.listId;
    setPassengerForm(passenger);
  }

  const updatePassengerFormPhone = (phone: string) => {
    passengerForm.phone = phone;
    setPassengerForm({...passengerForm});
  }

  const updatePassengerFormCivility = (civilityId: string) => {
    passengerForm.civilityId = civilityId;
    setPassengerForm({...passengerForm});
  }

  useEffect(() => {
    if (isSidebarOpen) {
      EventBus.dispatch('sidebar');
    } else {
      EventBus.dispatch('notSidebar');
    }
  }, [isSidebarOpen]);

  useEffect(() => {
    if (props.mission && props.mission.passengers) {
      setPassengers([...props.mission.passengers]);
    }
  }, [props.mission]);

  return {
    passengers,
    isSidebarOpen,
    isMainPassengerFormOpened,
    passengersAutocompletion,
    passengerFormRef,
    passengerForm,
    handleSidebarValidate,
    handleSidebarToggle,
    handleGetPassengerFromAutocomplete,
    handleClickOnAddPassengerButton,
    getPassengerLabel,
    resetPassenger,
    handleOpenMainPassengerForm,
    handleOpenPassengerDetailsForm,
    submitMainPassengerForm,
    handleCancelMainPassengerForm,
    handleSelectPassengerFromAutocomplete,
    createPassengerFromString,
    updatePassengerFormPhone,
    updatePassengerFormCivility,
  }
}

export default usePassenger;