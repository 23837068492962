import React from 'react';
import { t } from 'i18next';
import Checkbox from '../../../../components/form/checkbox/checkbox.component';
import {Alert, Button, FormControl, MenuItem, Select} from '@mui/material';
import IPassenger, { passengerMandatory } from '../../../../types/passenger.type';
import colors from '../../../../scss/utils/_variables.module.scss';
import {Avatar} from '@mui/material';
import FormLabel from '../../../../components/form/form-label/FormLabel.component';
import deletePassenger from '../../../../assets/img/icon/delete-passenger.svg';
import PassengerAddressesForm from './PassengerAddressesForm.component';
import Modal from '../../../../components/modal/Modal.component';
import usePassengerForm from '../../hook/passenger-form/use-passenger-form.hook';
import { ReactComponent as SaveIcon } from '../../../../assets/img/icon/save-white.svg';
import { ReactComponent as CalendarAddIcon }  from '../../../../assets/img/icon/calendar-add.svg';
import { ReactComponent as SmsEditIcon } from '../../../../assets/img/icon/sms-edit.svg';
import InputPhone from "../../../../common/components/input-phone.component";
import useCivilities from '../../hook/use-civilities.hook';
import InputTextField from "../../../../common/components/input-text-field.component";

interface Props {
  passenger: IPassenger;
  update?: (value: IPassenger) => void;
  submit?: (passenger: IPassenger) => void;
  onReset: () => void;
  inSidebar?: boolean;
}

const PassengerForm = React.forwardRef((props: Props, ref: any) => {
  const {
    passenger,
    hasPermissionPhoneMandatory,
    errors,
    errorsMessage,
    confirmationOpen,
    closeConfirmation,
    openConfirmation,
    HANDLE_CHANGE,
    TRY_FORM_SUBMIT,
    DELETE_PASSENGER,
    handleReset,
    hasPermissionQuotesCreate,
    redirectToCreateMissionWithPassengerId,
    redirectToCreateQuotationWithPassengerId
  } = usePassengerForm(props, ref);

  const {
    getCivilitiesByDispatch,
    translateCivility,
  } = useCivilities();

  return (
    <div className='passenger-form' data-testid='passenger-form'>
      <h5>
        <div className='header-form'>
          <div className='passenger-item'>
            <Avatar
              sx={{
                textTransform: 'uppercase',
                bgcolor: colors?.primaryColor,
                color: colors?.lightGrey,
                fontFamily: 'EuclidCircularA-Semibold',
                fontSize: '14px',
              }}
            >
              {passenger && passenger.firstname?.charAt(0) + passenger.lastname?.charAt(0)}
            </Avatar>
            <span className='body-medium-small passenger-item__infos__name title-info'>
              {!passenger.id && <>{t('pages.passengers.createNewPassenger')}</>}
              {passenger.id && (
                <>
                  {props.passenger.firstname} {props.passenger.lastname}
                </>
              )}
            </span>
          </div>
          <div className='passenger-item__delete'>
            {!props.inSidebar && props.passenger?.id && props.passenger.canEdit && (
              <img src={deletePassenger} alt='' onClick={openConfirmation} />
            )}
          </div>
        </div>
      </h5>

      <hr className='passenger-form__divider' />
      <div className='d-flex'>
        <div className='field field-civility'>
          <FormControl component="fieldset" disabled={!passenger.canEdit}>
            <h6>
              <FormLabel
                label={t(`form.passengerCreate.civility`)}
                mandatory={passengerMandatory.civilityId}
              />
            </h6>
            <Select
              id='civility'
              data-testid='civility'
              label=''
              value={passenger.civilityId ?? props.passenger.civilityId ?? ''}
              onChange={(e) => {
                HANDLE_CHANGE('civilityId', e.target.value);
              }}
            >
              {getCivilitiesByDispatch('', passenger).map(civility => (
                <MenuItem value={civility.id}>{translateCivility(civility.label)}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        <div className='field field-lastname'>
          <h6>
            <FormLabel
              label={t(`form.passengerCreate.lastname`)}
              mandatory={passengerMandatory.lastname}
            />
          </h6>
          <InputTextField
            id="name-passenger"
            disabled={!passenger.canEdit}
            initialValue={passenger.lastname ?? ''}
            onChange={(value) => {
              HANDLE_CHANGE('lastname', value);
            }}
            placeholder={t('form.passengerCreate.placeholderLastname')}
          />
        </div>
      </div>
      <div className='field'>
        <h6>
          <FormLabel
            label={t(`form.passengerCreate.firstname`)}
            mandatory={passengerMandatory.firstname}
          />
        </h6>
        <InputTextField
          id="firstname-passenger"
          disabled={!passenger.canEdit}
          initialValue={passenger.firstname ?? ''}
          onChange={(value) => {
            HANDLE_CHANGE('firstname', value);
          }}
          placeholder={t('form.passengerCreate.placeholderFirstName')}
        />
      </div>
      <div className='field'>
        <h6>
          <FormLabel label={t(`form.passengerCreate.email`)} mandatory={passengerMandatory.email} />
        </h6>
        <InputTextField
          id="mail-passenger"
          initialValue={passenger.email ?? ''}
          disabled={!passenger.canEdit}
          onChange={(value) => {
            HANDLE_CHANGE('email', value);
          }}
          placeholder={t('form.passengerCreate.placeholderEmail')}
        />
      </div>
      <div className='field'>
        <h6>
          <FormLabel label={t(`form.passengerCreate.phone`)} mandatory={hasPermissionPhoneMandatory} />
        </h6>
        <InputPhone
          id='phone-passenger'
          value={passenger.phone}
          disabled={!passenger.canEdit}
          onChange={(value) => {
            HANDLE_CHANGE('phone', value);
          }}
          placeholder={t('form.passengerCreate.placeholderPhone')}
        />
      </div>
      <div className='field'>
        <PassengerAddressesForm passenger_id={passenger.id} onChange={HANDLE_CHANGE} disabled={!passenger.canEdit} />
      </div>
      <div className='field'>
        <h6>
          <FormLabel
            label={t(`form.passengerCreate.detail`)}
            mandatory={passengerMandatory.details}
          />
        </h6>
        <InputTextField
          id="detail-passenger"
          maxLength={300}
          disabled={!passenger.canEdit}
          rows={4}
          initialValue={passenger.details ?? ''}
          onChange={(value) => {
            HANDLE_CHANGE('details', value);
          }}
        />
      </div>
      <div className='field'>
        <Checkbox
          data-testid='checkbox-mobility'
          disabled={!passenger.canEdit}
          id='mobility'
          label={t('form.passengerCreate.mobility')}
          value={passenger.mobility}
          onChange={(value) => {
            HANDLE_CHANGE('mobility', value);
          }}
        />
      </div>
      {Object.keys(errors).length > 0 && errorsMessage != '' && (
        <Alert severity="error">
          <div className='alert_html' dangerouslySetInnerHTML={{ __html: errorsMessage }}></div>
        </Alert>
      )}

      {!props.inSidebar && (
        <div className='footer-new-passenger'>
          <Button
            id='cancel-passenger'
            data-testid='cancel-passenger'
            onClick={() => {
              props.onReset();
              handleReset();
            }}
            variant='outlined'
            size='large'
          >
            {t('form.passengerCreate.cancel')}
          </Button>
          {passenger.canEdit &&
            <Button
              id='save-passenger'
              data-testid='save-passenger'
              onClick={TRY_FORM_SUBMIT}
              size='large'
            >
              <SaveIcon />
            </Button>
          }
          {passenger.id && (
            <>
              {hasPermissionQuotesCreate && (
                <Button
                  id='create-quotation-from-passenger'
                  data-testid='create_quotation_from_passenger'
                  onClick={async () => {
                    if (!passenger.canEdit || (await TRY_FORM_SUBMIT())) {
                      redirectToCreateQuotationWithPassengerId()
                    }
                  }}
                  variant='outlined'
                  endIcon={<SmsEditIcon />}
                  size='large'
                >
                  {t('form.action.create_quotation')}
                </Button>
              )}
              <Button
                id='create-booking-from-passenger'
                data-testid='create_booking_from_passenger'
                onClick={async () => {
                  if (!passenger.canEdit || (await TRY_FORM_SUBMIT())) {
                    redirectToCreateMissionWithPassengerId()
                  }
                }}
                variant='outlined'
                endIcon={<CalendarAddIcon />}
                size='large'
              >
                {t('form.action.create_booking')}
              </Button>
            </>
          )}
        </div>
      )}

      <Modal state={confirmationOpen} handleClose={closeConfirmation}>
        <div className='modal-delete-passenger'>
          <h5 className='confirmation__title'>{t('form.action.delete')}</h5>
          <div className='confirmation__buttons'>
            <Button
              id='cancel'
              data-testid='cancel_delete_passenger'
              onClick={closeConfirmation}
              size='large'
            >
              {t('form.action.cancel_delete_passenger')}
            </Button>
            <Button
              id='confirm'
              data-testid='delete_passenger'
              onClick={DELETE_PASSENGER}
              size='large'
            >
              {t('form.action.delete_passenger')}
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
});

export default PassengerForm;
