import { t } from 'i18next';
import { ChangeEvent, useRef } from 'react';
import trombone from '../../../assets/images/trombone.svg';

interface Props {
    handleFiles: (files: FileList) => void,
    multiple?: boolean,
    accept?: string,
}

export const InputFile = (props: Props) => {
  const hiddenFileInput = useRef(null); 

  const handleClick = () => {
    if (hiddenFileInput && hiddenFileInput.current) {
        (hiddenFileInput.current as HTMLInputElement).click();
    }  
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const target = event.target;
    if (target && target.files) {
        props.handleFiles(target.files);
    }
  };

  return (
    <>
      <button
        id='add-attachment'
        data-testid='add-attachment'
        type="button"
        className='text-uppercase request-button-secondary request-button-secondary--border-blue px-4'
        onClick={handleClick}
      >
        <img height={14} width={14} src={trombone} className='mr-2' />
        {t('form.attachments.uploadFilesLabel')}
      </button>
      <label style={{display:'none'}}>
        input-file
        <input
          type="file"
          multiple={props.multiple ?? false}
          onChange={handleChange}
          ref={hiddenFileInput}
          style={{display:'none'}}
          accept={props.accept}
        />
      </label>
    </>
  );
};