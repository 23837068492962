import { Components } from '@mui/material/styles';
import variables from '../../scss/utils/_variables.module.scss';
import errorIcon from '../../assets/img/icon/error.svg';

export const muiAlertTheme: Components['MuiAlert'] = {
  styleOverrides: {
    root: {
      display: 'flex',
      alignItems: 'center',
      backgroundColor: variables.white,
      padding: '1rem',
      gap: '0.5rem',
      fontFamily: [
        'EuclidCircularA-Semibold',
        '-apple-system',
        'BlinkMacSystemFont',
        'sans-serif',
      ].join(', '),
      fontWeight: 500,
      color: variables.mainTextColor,

      '& ol, & ul': {
        paddingInlineStart: 0,
        '& li': {
          color: '#385273',
          fontSize: '12px',
          fontWeight: 500,
          wordWrap: 'break-word',
        },
        '& li.error::before ': {
          display: 'inline-block',
          flexShrink: '0',
          width: '1rem',
          height: '1rem',
          marginRight: '0.5rem',
          verticalAlign: 'middle',
          background: `url(${errorIcon})`,
          backgroundSize: 'contain',
          content: '""',
        },
      },

      '& .title': {
        color: '#001c40',
        fontSize: '12px',
        fontWeight: 500,
        wordWrap: 'break-word',
        display: 'flex',
        gap: '0.5rem',
      },
    },
  },
};