import { useState, useEffect } from "react";
import {getAccessToken} from "../../../utils/getAccessToken";
import UserService from "../../../common/services/user/user.service";
import {AccountInfo, IPublicClientApplication} from "@azure/msal-browser";

const useFetchUser = (instance: IPublicClientApplication) => {
    const [user, setUser] = useState<AccountInfo | undefined>(undefined);
    const [userApi, setUserApi] = useState<any>(undefined);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<any>(null);

    useEffect(() => {
        const fetchUserData = async () => {
            try {
                setLoading(true);

                // Récupérer le premier compte utilisateur
                const localUser = instance.getAllAccounts()[0];
                setUser(localUser);

                // Obtenir le token d'accès
                const accessToken = await getAccessToken(instance);
                if (!accessToken) {
                    console.warn("No access token found");
                    return;
                }

                // Récupérer les données utilisateur via l'API
                const apiUser = await UserService.getMe(accessToken);
                setUserApi(apiUser);
            } catch (err) {
                console.error("Erreur lors de la récupération des informations utilisateur :", err);
                setError(err);
            } finally {
                setLoading(false);
            }
        };
        fetchUserData();
    }, [instance]);

    return { user, userApi, loading, error };
};

export default useFetchUser;
