import React from 'react';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import usePermissions from '../../config/usePermission';
import { PERMISSIONS } from '../../types/permissions.enum';
import { t } from 'i18next';
import {ReactComponent as CalendarAddIcon} from "../../assets/img/icon/calendar-add.svg";
import {ReactComponent as SmsEditIcon} from "../../assets/img/icon/sms-edit.svg";

const NewRequest = () => {
  const navigate = useNavigate();
  const HandleClick = () => navigate('/reservation');
  const HandleQuotationClick = () => navigate('/estimate');
  const hasPermissionQuotesCreate = usePermissions(PERMISSIONS['Quotes.Create']);

  return (
    <div className='new-request'>
      <div className='nr-elements d-flex'>
        <div>
          <Button
            id='request-button'
            data-testid='request-button'
            onClick={HandleClick}
            endIcon={<CalendarAddIcon />}
            size='large'
            sx={{ width: '17.063rem' }}
          >
            {t('form.action.create_booking')}
          </Button>
        </div>
        {hasPermissionQuotesCreate && (
          <div className='mx-2'>
            <Button
              id='quotation-button'
              data-testid='quotation-button'
              onClick={HandleQuotationClick}
              variant='outlined'
              endIcon={<SmsEditIcon />}
              size='large'
              sx={{ width: '17.063rem' }}
            >
              {t('form.action.create_quotation')}
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

export default NewRequest;
