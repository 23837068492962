import React, { useEffect, useState } from 'react';
import { MissionService } from '../../../services/missions/mission.service';
import IMission from '../../../types/mission.type';
import EventBus from '../../../common/EventBus';
import MissionArrayComponent from '../../mission-array/MissionArray.component';
import AuthService from '../../../common/services/user/auth.service';
import { useMsal } from '@azure/msal-react';
import { getAccessToken } from '../../../utils/getAccessToken';
import { BookingsTabs } from '../../../types/bookings-tab.enum';
import usePermissions from '../../../config/usePermission';
import { PERMISSIONS } from '../../../types/permissions.enum';
import errorService from '../../../common/services/notification/error.service';

interface Props {}
const BookingBlock: React.FC<Props> = (props) => {
  const [missions, setMissions] = useState<IMission[]>([]);
  const [activeTab, setActiveTab] = useState<BookingsTabs>(BookingsTabs.TodayBookings);
  const [isClicked, setIsClicked] = useState(false);
  const { instance } = useMsal();
  const hasPermissionBookingRead = usePermissions(PERMISSIONS['Workflow.Missions.PostRequest']);

  const handleTabClick = (tabIndex: BookingsTabs) => {
    setActiveTab(tabIndex);
    setIsClicked(!isClicked);
  };

  const handleActiveTab = async (
    search?: string,
    orderBy?: string,
    direction?: string,
    index?: number,
    limit?: number,
    contactId?: number[],
  ) => {
    getAccessToken(instance).then((token) => {
      if (!token) {
        console.warn("No access token found");
        return;
      }
      AuthService.test(token);
      errorService.clearErrorsByType('missionList');
      switch (activeTab) {
        case BookingsTabs.TodayBookings:
          EventBus.dispatch('loading');
          MissionService.getTodayBookings(token, search, orderBy, direction, index, limit, contactId).then(
            (missions) => {
              EventBus.dispatch('notLoading');
              setMissions(missions);
            },
          );
          break;
        case BookingsTabs.UpcomingBookings:
          EventBus.dispatch('loading');
          MissionService.getUpcomingBookings(token, search, orderBy, direction, index, limit, contactId).then(
            (missions) => {
              EventBus.dispatch('notLoading');
              setMissions(missions);
            },
          );
          break;
        case BookingsTabs.BookingRequests:
          if (hasPermissionBookingRead) {
            EventBus.dispatch('loading');
            MissionService.getBookingRequests(token, search, orderBy, direction, index, limit, contactId).then(
              (missions) => {
                EventBus.dispatch('notLoading');
                setMissions(missions);
              },
            );
          }
          break;
        case BookingsTabs.QuotationsRequests:
          EventBus.dispatch('loading');
          MissionService.getQuotationsRequests(
            token,
            search,
            orderBy,
            direction,
            index,
            limit,contactId
          ).then((missions) => {
            EventBus.dispatch('notLoading');
            setMissions(missions);
          });
          break;
        case BookingsTabs.PastBookings:
          EventBus.dispatch('loading');
          MissionService.getPastBookings(token, search, orderBy, direction, index, limit, contactId).then(
            (missions) => {
              EventBus.dispatch('notLoading');
              setMissions(missions);
            },
          );
          break;
      }
    });
  };

  useEffect(() => {
    EventBus.dispatch('notLoading');
    handleActiveTab();
  }, [activeTab, isClicked]);

  useEffect(() => {
    const bookingActiveTab = sessionStorage.getItem('bookingActiveTab');
    if (bookingActiveTab && Number(bookingActiveTab) != Number(activeTab)) {
      //setActiveTab(Number(bookingActiveTab)); //TODO : A fix, on change d'onglet mais on ne recharge pas la liste
    }
  }, []);

  useEffect(() => {
    sessionStorage.setItem('bookingActiveTab', String(activeTab));
  }, [activeTab]);

  return (
    <div className='booking-block' data-testid='booking-block'>
      <MissionArrayComponent
        missions={missions}
        handleTabClick={handleTabClick}
        handleReload={handleActiveTab}
        activeTab={activeTab}
      />
    </div>
  );
};

export default BookingBlock;
