export const formDataBuilder = () => {
    const buildFormData = (formData: FormData, data: any, parentKey?: string | undefined): FormData => {
        if (isComplexObject(data)) {
            Object.keys(data).forEach(key => {
                return buildFormData(formData, data[key], parentKey ? `${parentKey}[${key}]` : key);
            });
        } else {
            const value = data === null ? '' : data;
        
            if (parentKey) {
                formData.append(parentKey, value);
            }
        }

        return formData;
    }
      
    const convertObjectToFormData = (json: object): FormData => {
        let formData = new FormData();
        formData = buildFormData(formData, json);

        return formData;
    }

    const isComplexObject = (data: any): boolean => {
        return data && typeof data === 'object' && !(data instanceof Date) && !(data instanceof File) && !(data instanceof Blob);
    }

    return convertObjectToFormData;
}