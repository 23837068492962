import { t } from 'i18next';
import { Civility } from '../../../types/civility.type';
import IPassenger from '../../../types/passenger.type';

const CIVILITIES: { [key: string]: Civility[] } = {
  // TODO : set good values for prod
  chabe: [
    { id: '3', label: 'mr' },
    { id: '9', label: 'mrs' },
    { id: '1', label: 'ms' },
  ],
  chabedev: [
    { id: '3', label: 'mr' },
    { id: '9', label: 'mrs' },
    { id: '1', label: 'ms' },
  ],
  chabelimited: [
    { id: '3', label: 'mr' },
    { id: '2', label: 'mrs' },
    { id: '1', label: 'ms' },
  ],
  chabecra: [
    { id: '3', label: 'mr' },
    { id: '2', label: 'mrs' },
    { id: '1', label: 'ms' },
  ],
  chabese: [
    { id: '3', label: 'mr' },
    { id: '2', label: 'mrs' },
    { id: '1', label: 'ms' },
  ],
  chabetls: [
    { id: '3', label: 'mr' },
    { id: '2', label: 'mrs' },
    { id: '1', label: 'ms' },
  ],
  chabebdx: [
    { id: '3', label: 'mr' },
    { id: '2', label: 'mrs' },
    { id: '1', label: 'ms' },
  ],
  chabegva: [
    { id: '3', label: 'mr' },
    { id: '2', label: 'mrs' },
    { id: '1', label: 'ms' },
  ],
   chabegcc: [
    { id: '3', label: 'mr' },
    { id: '2', label: 'mrs' },
    { id: '1', label: 'ms' },
  ],
  chabeit: [
    { id: '3', label: 'ms' },
    { id: '2', label: 'mr' },
    { id: '1', label: 'mrs' },
  ],
  // TODO : add the others dispatches
};

function useCivilities() {
  const translateCivility = (label: string): string => {
    const translationKey = 'form.passenger.civility.' + label;
    const translatedLabel = t(translationKey);
    if (translatedLabel === translationKey) {
      return label;
    }

    return translatedLabel;
  };

  const translateCivilityByIdAndDispatch = (id: string, dispatch: string): string | undefined => {
    if (CIVILITIES[dispatch]) {
      const civilityFromId = CIVILITIES[dispatch].find((civility) => civility.id === id);
      if (civilityFromId) {
        return translateCivility(civilityFromId.label);
      }
    }

    if (isNaN(Number(id))) {
      return translateCivility(id);
    }

    return undefined;
  };

  const getCivilitiesByDispatch = (dispatch: string, passenger: IPassenger): Civility[] => {
    let civilities = CIVILITIES.chabedev;
    if (CIVILITIES[dispatch] !== undefined) {
      civilities = CIVILITIES[dispatch];
    }

    if (!civilities.find((civility) => civility.id === passenger.civilityId)) {
      if (passenger.civilityId && passenger.civilityLabel) {
        civilities.push({ id: passenger.civilityId, label: passenger.civilityLabel });
      }
    }

    return civilities;
  };

  return {
    translateCivility,
    translateCivilityByIdAndDispatch,
    getCivilitiesByDispatch,
  };
}

export default useCivilities;
