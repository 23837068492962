/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */

import React from 'react';
import { t } from 'i18next';
import IMission, { missionMandatory } from '../../../types/mission.type';
import {Alert, Button} from '@mui/material';
import Sidebar from '../../../components/sidebar/Sidebar.component';
import PassengerDetailsFormComponent from './passenger-details/PassengerDetailsForm.component';
import PassengerDetailsViewComponent from './passenger-details/PassengerDetailsView.component';
import FormLabel from '../../../components/form/form-label/FormLabel.component';
import PassengerForm from './passenger-form/PassengerForm.component';
import {ReactComponent as AddRoundIcon} from '../../../assets/img/icon/add-round.svg';
import CloseCircle from '../../../assets/img/icon/close-circle.svg';
import PassengerCredentialsComponent from './passenger-details/PassengerCredentials.component';
import usePassenger from '../hook/use-passenger.hook';
import IPassenger from '../../../types/passenger.type';
import usePassengerForm from '../hook/passenger-form/use-passenger-form.hook';

interface Props {
  mission: IMission;
  updateMission: (property: string, value: any) => void;
}

const Passenger: React.FC<Props> = (props) => {
  const {
    passengers,
    isSidebarOpen,
    isMainPassengerFormOpened,
    passengersAutocompletion,
    passengerFormRef,
    passengerForm,
    handleSidebarValidate,
    handleSidebarToggle,
    handleGetPassengerFromAutocomplete,
    handleClickOnAddPassengerButton,
    getPassengerLabel,
    resetPassenger,
    handleOpenMainPassengerForm,
    handleOpenPassengerDetailsForm,
    submitMainPassengerForm,
    handleCancelMainPassengerForm,
    handleSelectPassengerFromAutocomplete,
    createPassengerFromString,
    updatePassengerFormPhone,
    updatePassengerFormCivility,
  } = usePassenger(props);

  const {
    errors,
    errorsMessage,
    resetErrors,
    HANDLE_VALIDATE
  } = usePassengerForm( {}, null);

  const handleSidebarValidateWithCheck = async () => {
    if (!isMainPassengerFormOpened && !HANDLE_VALIDATE(passengerForm)) {
      return;
    }
    await handleSidebarValidate();
  };

  const handleClickOnAddPassengerButtonAndCleanError = () => {
    resetErrors();
    handleClickOnAddPassengerButton();
  }

  return (
    <div id='passenger' data-testid='passenger'>
      <h6>
        <FormLabel label={t(`form.passenger.title`)} mandatory={missionMandatory.passengers} />
      </h6>
      <div className='d-flex flex-column'>
        { passengers.filter(passenger => passenger.delete !== true).map((passenger: IPassenger) =>
          {
            return <div className='d-flex' id={JSON.stringify(passenger)} key={JSON.stringify(passenger)}>
              <div className='input-passenger-name input-passenger-name-background' data-testid='input-passenger-name'>
                <PassengerCredentialsComponent
                  passenger={passenger}
                  handleGetPassenger={handleGetPassengerFromAutocomplete}
                  handleClickOnInputSuffix={handleOpenMainPassengerForm}
                  handle_change={handleSelectPassengerFromAutocomplete}
                  getPassengerLabel={getPassengerLabel}
                  passengersAutocompletion={undefined}
                  isInSidebar={false}
                  createPassengerFromString={createPassengerFromString}
                  updatePassengerFormPhone={updatePassengerFormPhone}
                  updatePassengerFormCivility={updatePassengerFormCivility}
                  handlePassengerDetailsSidebar={handleOpenPassengerDetailsForm} />
                <PassengerDetailsViewComponent passager={passenger} />
              </div>
              <img 
                src={CloseCircle} alt="close-circle" 
                className='align-self-start m-1 icon__clickable'
                onClick={() => resetPassenger(passenger)}
              />
            </div>
          }
        ) }
        </div>

        {isSidebarOpen && (
          <>
            <Sidebar onClose={handleSidebarToggle} onValidate={handleSidebarValidateWithCheck}>
              {isMainPassengerFormOpened ? (
                <PassengerForm
                  ref={passengerFormRef}
                  passenger={passengerForm}
                  onReset={handleCancelMainPassengerForm}
                  submit={submitMainPassengerForm}
                  inSidebar={true} />
              ) : (
                <>
                  <PassengerDetailsFormComponent
                    passenger={passengerForm}
                    handleGetPassenger={handleGetPassengerFromAutocomplete}
                    getPassengerLabel={getPassengerLabel}
                    passengersAutocompletion={passengersAutocompletion}
                    handleSidebarPassengerFormOpen={handleOpenMainPassengerForm}
                    handle_change={handleSelectPassengerFromAutocomplete}
                    createPassengerFromString={createPassengerFromString}
                    updatePassengerFormPhone={updatePassengerFormPhone}
                    updatePassengerFormCivility={updatePassengerFormCivility}
                    handlePassengerDetailsSidebar={handleOpenPassengerDetailsForm} />
                  {Object.keys(errors).length > 0 && errorsMessage != '' && (
                    <Alert severity="error">
                      <div className='alert_html' dangerouslySetInnerHTML={{ __html: errorsMessage }}></div>
                    </Alert>
                  )}
                </>
              )}
            </Sidebar>
            <div className='loading sidebar__bg' />
          </>
        )}
          
        <div className='input-passenger-details'>
          <Button
            id='add-passengers'
            data-testid='add-passengers'
            onClick={handleClickOnAddPassengerButtonAndCleanError}
            startIcon={<AddRoundIcon />}
            variant='outlined'
            size='large'
          >
            {t('form.passenger.details.button-sidebar')}
          </Button>
        </div>
    </div>
  );
};

export default Passenger;
