import React from 'react';
import { Divider, Stack, Typography } from '@mui/material';
import IPassenger from '../../../types/passenger.type';
import { ReactComponent as Adult } from '../../../assets/img/icon/profile.svg';
import { ReactComponent as Children } from '../../../assets/img/icon/children.svg';
import { ReactComponent as Infant } from '../../../assets/img/icon/infant.svg';
import { ReactComponent as BabySeat } from '../../../assets/img/icon/baby-seat.svg';
import { ReactComponent as ChildSeat } from '../../../assets/img/icon/child-seat.svg';
import { ReactComponent as CabinBaggage } from '../../../assets/img/icon/cabin-baggage.svg';
import { ReactComponent as CheckedBaggage } from '../../../assets/img/icon/checked-baggage.svg';
import { ReactComponent as WhellChair } from '../../../assets/img/icon/whellchair.svg';
import { ReactComponent as PMR } from '../../../assets/img/icon/PMR.svg';
import MissionRowPassengerIcon from './mission-row-passenger-icon.component';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';
import PhoneLabelComponent from '../../../components/label/phone-label.component';
import getDispatch from '../../../config/userDispatch';
import useCivilities from '../../passenger/hook/use-civilities.hook';


export interface MissionRowPassengerDetailProps {
  passengers?: IPassenger[];
}

const MissionRowPassengerDetail = (props: MissionRowPassengerDetailProps) => {
  const {
    translateCivilityByIdAndDispatch
  } = useCivilities();

  const dispatch = getDispatch();

  const { t } = useTranslation('ns1', { i18n });

  return <Stack direction="row" spacing={1} width="100%" height="100%" justifyContent="space-between">
    <div className="mission-array-detail-container">
      <Typography variant="inputLabel">
        {t('components.booking.row.passenger-title')}
      </Typography>
      <Stack direction="column" marginTop={2} spacing={1} justifyContent="space-between">
        {props.passengers?.map((passenger, index) => {
          return <>
            <Stack direction="row" spacing={1} justifyContent="space-between">
              <Typography variant="body2" sx={{color: '#3A3A3C'}}>
                {dispatch && props.passengers && props.passengers[0] && props.passengers[0].civilityId && (translateCivilityByIdAndDispatch(props.passengers[0].civilityId, dispatch)?.concat(' ') ?? '')}
                {passenger.lastname} {passenger.firstname}
              </Typography>
              {passenger.phone &&
                <PhoneLabelComponent phoneNumber={passenger.phone} />
              }
            </Stack>
            <Stack direction="row" spacing={1} justifyContent="space-between">
              <Stack direction="row" gap={'24px'}>
                <MissionRowPassengerIcon count={passenger.adults} icon={<Adult height={25} width={25} />} />
                <MissionRowPassengerIcon count={passenger.childrens} icon={<Children height={25} width={25} />} />
                <MissionRowPassengerIcon count={passenger.infants} icon={<Infant height={25} width={25} />} />
                <MissionRowPassengerIcon count={passenger.babySeats} icon={<BabySeat height={25} width={25} />} />
                <MissionRowPassengerIcon count={passenger.childSeats} icon={<ChildSeat height={30} width={30} />} />
                <MissionRowPassengerIcon count={passenger.cabinBaggages}
                                         icon={<CabinBaggage height={25} width={25} />} />
                <MissionRowPassengerIcon count={passenger.checkedBaggages}
                                         icon={<CheckedBaggage height={25} width={25} />} />
                <MissionRowPassengerIcon count={passenger.wheelChairs} icon={<WhellChair height={20} width={20} />} />
              </Stack>
              {passenger.mobility && <PMR height={20} width={20} />}
            </Stack>
          </>;
        })}
      </Stack>
    </div>
    <Divider orientation="vertical" flexItem />
  </Stack>;
};

export default MissionRowPassengerDetail;