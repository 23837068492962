import { Grid, Typography } from '@mui/material';
import React from 'react';
import MissionRowPassengerDetail from './mission-row-passenger-detail';
import MissionRowAddressDetail from './mission-row-address-detail';
import IMission from '../../../types/mission.type';
import MissionRowMissionDetail from './mission-row-mission-detail.component';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';


export interface MissionArrayDetailProps {
  mission: IMission;
  handlePopInQuotation: () => void;
}

const MissionArrayDetail = (props: MissionArrayDetailProps) => {
  const { t } = useTranslation('ns1', { i18n });
  return <>
    <Grid
      data-testid={`mission-row-details${props.mission.id}`}
      container
      sx={{ padding: '10px' }}>
      <Grid item xs={5}>
        <MissionRowPassengerDetail passengers={props.mission.passengers} />
      </Grid>
      <Grid item xs={5}>
        <MissionRowAddressDetail steps={props.mission.steps} />
      </Grid>
      <Grid item xs={2}>
        <MissionRowMissionDetail mission={props.mission} handlePopInQuotation={props.handlePopInQuotation} />
      </Grid>
      {props.mission.description &&
        <Grid item xs={12} marginTop={2} className="mission-array-detail-container">
          <Typography variant="inputLabel" marginBottom={2}>
            {t('components.booking.row.request-title')}
          </Typography>
          <Typography variant="body2" sx={{color: '#385273' }}>
            {props.mission.description}
          </Typography>
        </Grid>
      }
    </Grid>
  </>;
};

export default MissionArrayDetail;
