import Dropdown from '../../../components/dropdown/Dropdown.component';
import useChangeLanguage from '../hook/use-change-language.hook';

const LanguageSelector = () => {
  const { selectedLanguage, languages, handleLanguageSelection } = useChangeLanguage();

  return (
    <div>
      <div id='language-selector' data-testid='language-selector'>
        <Dropdown
          elements={languages}
          handleSelectedElement={handleLanguageSelection}
          selectedElement={selectedLanguage}
          beforeIcon={null}
          placeHolder={`${selectedLanguage?.value}`}
          customClass='-lang'
          showScrollIndicators={false}
        />
      </div>
    </div>
  );
};

export default LanguageSelector;
