import { Button, Checkbox, Chip, FormControlLabel, FormGroup, Popover, Stack, Typography } from '@mui/material';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { ReactComponent as UserCircle } from '../../../assets/img/icon/user-circle.svg';
import { ReactComponent as Trash } from '../../../assets/img/icon/Trash_filter.svg';
import { useRetrieveAllBooker } from '../hook/use-retrieve-all-booker.hook';
import { BookerDto } from '../dto/booker.dto';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';
import CloseIcon from '@mui/icons-material/Close';


export interface Props {
  onChange: (contactId: number[]) => void;
  contactId?: number[];
}

const MissionArrayBookerFilterComponent = (props: Props) => {
  const { t } = useTranslation('ns1', { i18n });
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedBookers, setSelectedBookers] = useState<BookerDto[]>([]);

  const { data } = useRetrieveAllBooker();

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleOnChange = (event: ChangeEvent<HTMLInputElement>, booker: BookerDto) => {
    if (event.target.checked) {
      saveBookerList([...selectedBookers, booker]);
    } else {
      saveBookerList(selectedBookers.filter(item => item.id !== booker.id));
    }

  };

  const handleDeleteBooker = (booker: BookerDto) => {
    console.log('booker to delete', booker.id);
    console.log('selectedList', selectedBookers);
    saveBookerList(selectedBookers.filter(item => item.contactId !== booker.contactId));
  };

  const saveBookerList = (bookerList: BookerDto[]) => {
    setSelectedBookers(bookerList);
    props.onChange(bookerList.map(item => item.contactId));
  };

  const open = Boolean(anchorEl);

  useEffect(() => {
    if (props.contactId && props.contactId.length === 0) {
      setSelectedBookers([]);
    }
  }, [props.contactId]);

  return <>
    <Stack direction="row" spacing={2} padding={2} paddingBottom={0} sx={{ alignItems: "center" }}>
      <Typography variant='smallTitle'>
        {t('components.booking.filter.title')} :
      </Typography>
      <Button data-testId="booker-button" variant="filter" onClick={handleClick}
              endIcon={<UserCircle />}>
        {t('components.booking.filter.button')}
      </Button>
      <Popover open={open} onClose={handleClose} anchorEl={anchorEl}
               anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
               transformOrigin={{ vertical: 'top', horizontal: 'left' }}
               sx={{ marginTop: 2 }}>
        <FormGroup data-testId="FormGroup-booker">
          {data?.map((item: BookerDto, index: React.Key | null | undefined) => (
            <FormControlLabel
              control={<Checkbox checked={selectedBookers.includes(item)}
                                 onChange={(event) => handleOnChange(event, item)}
                                 disabled={!item.contactId}
                                 name={`${item.firstName || ''} ${item.lastName || ''}`.trim()}
                                 sx={{ color: '#001C40', '&.Mui-checked': { color: '#001C40' } }} />}
                                 label={`${item.firstName || ''} ${item.lastName || ''}`.trim()} key={index} />
          ))}
        </FormGroup>
      </Popover>
    </Stack>
    {
      (selectedBookers.length > 0) && (
        <Stack direction="row" spacing={2} padding={2} paddingBottom={0}>
          {selectedBookers.map(((item, index) => (
            <Chip
              data-testId={`booker-chip-${item.id}`}
              key={index}
              label={`${item.firstName || ''} ${item.lastName || ''}`.trim() || t('components.booking.filter.unknownBooker')}
              onDelete={() => handleDeleteBooker(item)}
              deleteIcon={<CloseIcon />}
            />
          )))}
          <Button size='small' onClick={() => saveBookerList([])} endIcon={<Trash />}>
            {t('components.booking.filter.reset')}
          </Button>
        </Stack>
      )
    }
  </>;
};

export default MissionArrayBookerFilterComponent;
