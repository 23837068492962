import { Components } from '@mui/material/styles';
import variables from '../../scss/utils/_variables.module.scss';

export const muiFormControl: Components['MuiFormControl'] = {
  styleOverrides: {
    root: {
      width: '100%',
      backgroundColor: variables.white,
    }
  },
};