import { useEffect, useImperativeHandle, useState } from "react";
import IPassenger, { passengerMandatory } from "../../../../types/passenger.type";
import EventBus from "../../../../common/EventBus";
import { getAccessToken } from "../../../../utils/getAccessToken";
import PassengerService from "../../../../services/passengers/passenger.service";
import { useMsal } from "@azure/msal-react";
import React from "react";
import usePermissions from "../../../../config/usePermission";
import i18n from '../../../../i18n/config';
import IMissionErrors from "../../../../types/mission.error.type";
import IPassengerErrors from "../../../../types/passenger.error.type";
import { PERMISSIONS } from "../../../../types/permissions.enum";
import { errorsToMessage } from "../../../../utils/errorsUtils";
import {useNavigate} from "react-router-dom";
import { matchIsValidTel } from 'mui-tel-input';
import toastService from "../../../../common/services/notification/toast.service";
import {t} from "i18next";

interface Props {
  passenger?: IPassenger;
  update?: (value: IPassenger) => void;
  submit?: (passenger: IPassenger) => void;
  onReset?: () => void;
  inSidebar?: boolean;
}

function usePassengerForm(props: Props, ref: any) {
  const { instance } = useMsal();
  const [passenger, setPassenger] = useState({} as IPassenger);
  const [errors, _setErrors] = useState<IMissionErrors>({} as IMissionErrors);

  const errorsRef = React.useRef(errors);
  const setErrors = (data: IMissionErrors) => {
    errorsRef.current = data;
    _setErrors(data);
  };
  const [errorsMessage, setErrorsMessage] = useState('');
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const hasPermissionPhoneMandatory = usePermissions(PERMISSIONS['Missions.PassengerPhoneMandatory']);
  const hasPermissionQuotesCreate = usePermissions(PERMISSIONS['Quotes.Create']);

  const navigate = useNavigate();
  const redirectToCreateMissionWithPassengerId = () => navigate('/reservation?passengerId=' + passenger.id);
  const redirectToCreateQuotationWithPassengerId = () => navigate('/estimate?passengerId=' + passenger.id);

  useImperativeHandle(ref, () => ({
    TRY_FORM_SUBMIT: TRY_FORM_SUBMIT,
  }));

  const openConfirmation = () => {
    setConfirmationOpen(true);
  };

  const closeConfirmation = () => {
    setConfirmationOpen(false);
  };

  const validateEmail = (value: string) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(value);
  };

  const HANDLE_VALIDATE = (values: IPassenger) => {
    let errors = {} as IPassengerErrors;
    for (const [key, value] of Object.entries(passengerMandatory)) {
      if (value && (!values.hasOwnProperty(key) || !values[key] || values[key] === '')) {
        errors = { ...errors, [key]: 'mandatory' };
      }
      if (!values.phone && hasPermissionPhoneMandatory) {
        errors = { ...errors, phone: 'mandatory' };
      }
      if (values.phone && hasPermissionPhoneMandatory) {
        const testPhone = matchIsValidTel(values.phone, {
          onlyCountries: [],
          excludedCountries: [],
          continents: []
        })
        if (!testPhone) {
          errors = {...errors, phone: 'format'};
        }
      }
      if (values.email && !validateEmail(values.email)) {
        errors = { ...errors, email: 'format-error' };
      }
    }
    setErrorsMessage('');
    setErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const HANDLE_SET_ERRORS_MESSAGE = async () => {
    setErrorsMessage(errorsToMessage(errors));
  };

  const HANDLE_CHANGE = (property: string, value: any) => {
    setPassenger((prevState) => ({ ...prevState, [property]: value }));
  };

  const TRY_FORM_SUBMIT = async (): Promise<boolean> => {
    if (!HANDLE_VALIDATE(passenger)) {
      return false;
    }
    EventBus.dispatch('loading');

    try {
      const token = await getAccessToken(instance);
      if (!token) {
        console.warn("No access token found");
        return false;
      }
      const response = await PassengerService.createOrUpdate(token, passenger);
      if (response && response['data']) {
        setPassenger(response['data'] as IPassenger);
        if (props.submit) {
          props.submit(response['data'] as IPassenger);
        }
        if (props.update) {
          props.update(response['data'] as IPassenger);
        }
      }

      if (passenger.id) {
        toastService.success(t('success.passenger.update'));
      } else {
        toastService.success(t('success.passenger.create'));
      }

      EventBus.dispatch('notLoading');

      return true;

    } catch (error: any) {
      setErrorsMessage('');
      // Gère les erreurs, en affichant un message technique s'il n'y a pas d'erreurs spécifiques
      if (!error.errors && error.message) {
        setErrors({ technical: error.message });
      } else if (!error.errors) {
        setErrors({ technical: 'Failed' });
      } else {
        setErrors(error.errors);
      }

      // Indique que le chargement est terminé, même en cas d'erreur
      EventBus.dispatch('notLoading');
      return false; // Retourne false en cas d'erreur
    }
  };

  const DELETE_PASSENGER = async () => {
    const id = passenger.id;
    if (id !== undefined) {
      try {
        const token = await getAccessToken(instance);
        if (!token) {
          console.warn("No access token found");
          return;
        }
        await PassengerService.delete(token, id);
        if (props.submit) {
          props.submit(passenger);
        }
        if (props.onReset) {
          props.onReset();
        }
      } catch (error: any) {
        if (!error.errors && error.message) {
          setErrors({technical: error.message});
        } else {
          setErrors(error.errors);
        }
      }
    }
  };

  const handleReset = () => {
    setPassenger({} as IPassenger);
  };

  const resetErrors = () => {
    setErrors({} as IPassengerErrors);
  };
  // == Hooks ===============================================================

  useEffect(() => {
    if (props.passenger) {
      if (passenger?.id !== props.passenger?.id) {
        resetErrors();
      }
      setPassenger(props.passenger);
    }
  }, [props.passenger]);

  useEffect(() => {
    i18n.on('languageChanged', HANDLE_SET_ERRORS_MESSAGE);

    return () => {
      i18n.off('languageChanged', HANDLE_SET_ERRORS_MESSAGE);
    };
  }, [i18n]);

  useEffect(() => {
    HANDLE_SET_ERRORS_MESSAGE();
  }, [errors]);

  return {
    passenger,
    hasPermissionPhoneMandatory,
    errors,
    errorsMessage,
    confirmationOpen,
    closeConfirmation,
    openConfirmation,
    HANDLE_CHANGE,
    TRY_FORM_SUBMIT,
    DELETE_PASSENGER,
    HANDLE_VALIDATE,
    handleReset,
    resetErrors,
    hasPermissionQuotesCreate,
    redirectToCreateMissionWithPassengerId,
    redirectToCreateQuotationWithPassengerId
  }
}

export default usePassengerForm;