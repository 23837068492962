import { t } from 'i18next';
import {Button, Stack, Typography} from '@mui/material';
import PassengerForm from '../../domain/passenger/component/passenger-form/PassengerForm.component';
import passengers from '../../assets/img/icon/passengers-page.svg';
import React, { useState } from 'react';
import IPassenger from '../../types/passenger.type';
import PassengerSearch from '../../components/passenger-search/PassengerSearch.component';

const Passengers: React.FC = () => {
  const [passenger, setPassenger] = useState<IPassenger | null>(null);
  const [key, setKey] = useState(0);
  const handleNewPassenger = () => {
    setPassenger({
      civilityId: '',
      lastname: '',
      firstname: '',
      phone: '',
      email: '',
      details: '',
      mobility: false,
      canEdit: true
    } as IPassenger);
  };

  const editPassenger = (passenger: IPassenger) => {
    setPassenger(passenger);
  };

  const handleReset = () => {
    setPassenger(null);
    setKey(key + 1);
  };

  const handleSubmit = (passenger: IPassenger) => {
    setPassenger(passenger);
  };

  return (
    <div id='passenger' data-testid='passenger' className='passenger-page'>
      <div className='d-flex justify-content-between'>
        <h2>{t('pages.passengers.title')}</h2>
        <Button
          id='create-passenger'
          data-testid='create-passenger'
          className='button-create-passenger'
          onClick={handleNewPassenger}
          size='large'
        >
          {t('pages.passengers.createNewPassenger')}
        </Button>
      </div>
      <div
        key={key}
        className='d-flex justify-content-between passenger-page-content body-form-page'
      >
        <div className='search'>
          <PassengerSearch edit={editPassenger} activePassenger={passenger} />
        </div>

        <div className='form-container'>
          {passenger && (
              <PassengerForm passenger={passenger} onReset={handleReset} submit={handleSubmit} />
          )}
          {!passenger && (
            <Stack padding={20} spacing={4} sx={{alignItems: 'center', justifyContent: "center"}}>
              <div>
                <img src={passengers} alt={t('accessibility.alt.icon-my-passenger-page')} />
              </div>
              <Typography sx={{fontSize: '20px', fontWeight: '500', maxWidth: '18.75rem', textAlign: 'center'}}>
                {t('pages.passengers.selectPassenger')}
              </Typography>
            </Stack>
          )}
        </div>
      </div>
    </div>
  );
};

export default Passengers;
