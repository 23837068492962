import Attachment from "../../../types/attachment.type";
import fileSvg from "../../../assets/images/file.svg";
import trashSvg from "../../../assets/images/trash.svg";
import moreSquare from "../../../assets/images/more-square.svg";
import { t } from "i18next";
import { Button, Stack, Typography } from "@mui/material";
import Modal from '../../../components/modal/Modal.component';
import { useState } from "react";
import colors from '../../../scss/utils/_variables.module.scss';

type Props = {
    file: File | Attachment;
    download?: (file: Attachment) => void;
    handleRemove?: (file: File | Attachment) => void;
};

const AttachmentComponent: React.FC<Props> = (props) => {
    const [modalOpen, setModalOpen] = useState<boolean>(false);
    const handleOpen = () => setModalOpen(true);
    const handleClose = () => setModalOpen(false);

    const getFormattedSize = () => {
        let size = (props.file?.size ?? 0) / 1024 / 1024;
        if (size >= 1) {
            return size.toFixed(2) + ' ' + t('form.attachments.sizeMB');
        }
        size = size * 1024;
        return size.toFixed(2) + ' ' + t('form.attachments.sizeKB');
    }

    const handleRemove = () => {
        if (props.handleRemove && 'id' in props.file) {
            handleOpen();
        } else {
            removeFile();
        }
    }

    const removeFile = () => {
        if (props.handleRemove) {
            props.handleRemove(props.file);
            handleClose();
        }
    }

    const download = () => {
        if (props.download && 'id' in props.file) {
            props.download(props.file);
        }
    }

    return (
        <div className="attachment-item">
            <img width={17} height={21} className="attachment-item__icon" src={fileSvg} />
            <div className="attachment-item__infos">
                <span className="body-normal-small attachment-item__infos__name" title={props.file.name}>{props.file.name}</span>
                <span className="body-normal-small attachment-item__infos__size">{getFormattedSize()}</span>
            </div>
            {props.handleRemove &&
                <img onClick={handleRemove} width={25} height={25} className="attachment-item__icon__suffix" src={trashSvg} alt='delete icon' />
            }
            {props.download && 'token' in props.file && props.file.token &&
                <img onClick={download} width={25} height={25} className="attachment-item__icon__suffix" src={moreSquare} alt='more-square icon' />
            }
            <Modal state={modalOpen} handleClose={handleClose}>
                <h5 className='confirmation__title'>{t('form.action.button_delete')} ?</h5>
                <Stack direction="column" sx={{ margin: '0 4rem 0 4rem', justifyContent: "center", alignItems: "center" }}>
                    <Stack sx={{ width: '126px', height: '126px', alignItems: 'center', justifyContent: 'center', borderRadius: '50%', backgroundColor: colors.lightGrey, marginBottom: '2rem' }}>
                        <img width={60} height={60} src={trashSvg} alt='delete block' />
                    </Stack>
                    <p><Typography variant="bodyModalSubTitle">{t(`form.action.delete_attachment`)}</Typography></p>
                </Stack>
                <Stack direction="row" spacing={6} paddingTop={2} sx={{ margin: '0 4rem 2rem 4rem', justifyContent: "center" }}>
                    <Button
                        id='cancel'
                        onClick={handleClose}
                        variant='outlined'
                    >
                        {t('form.action.cancel')}
                    </Button>
                    <Button
                        id='confirm'
                        onClick={removeFile}
                    >
                        {t('form.action.button_delete')}
                    </Button>
                </Stack>
            </Modal>
        </div>
    );
}

export default AttachmentComponent;