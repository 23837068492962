import React, { useEffect, useState } from 'react';
import { Box, InputAdornment, TextField, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';

interface InputTextFieldProps {
  id?: string;
  maxLength?: number;
  rows?: number;
  onChange?: (value: string) => void;
  initialValue?: string;
  placeholder?: string;
  startIcon?: React.ReactNode;
  endIcon?: React.ReactNode;
  disabled?: boolean;
}

const InputTextField: React.FC<InputTextFieldProps> = ({
                                                         maxLength,
                                                         rows = 1,
                                                         onChange,
                                                         initialValue = '',
                                                         id = 'textField',
                                                         placeholder = '',
                                                         startIcon,
                                                         endIcon,
                                                         disabled = false,
                                                       }) => {
  const [value, setValue] = useState<string>(initialValue ?? '');
  const { t } = useTranslation('ns1', { i18n });

  useEffect(() => {
    setValue(initialValue ?? '');
  }, [initialValue]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const newValue = event.target.value;

    if (maxLength === undefined || newValue.length <= maxLength) {
      setValue(newValue);
      if (onChange) {
        onChange(newValue);
      }
    }
  };

  return (
    <Box sx={{ margin: 'auto' }}>
      <TextField
        id={id ?? 'text-field'}
        data-testid={id}
        variant="outlined"
        disabled={disabled}
        fullWidth
        value={value}
        onChange={handleChange}
        rows={rows ?? 1}
        multiline={rows > 1}
        InputLabelProps={{
          shrink: true,
        }}
        error={maxLength !== undefined && value.length === maxLength}
        placeholder={placeholder ?? ''}
        InputProps={{
          startAdornment: startIcon && (
            <InputAdornment position="start">{startIcon}</InputAdornment>
          ),
          endAdornment: endIcon && (
            <InputAdornment position="end">{endIcon}</InputAdornment>
          ),
        }}
      />
      {maxLength !== undefined && (
        <Typography
          variant="body2"
          color="textSecondary"
          sx={{
            textAlign: 'right',
            marginTop: '5px',
            color: value.length === maxLength ? 'red' : 'textSecondary',
          }}
        >
          {value.length}/{maxLength} {t(`form.text.characters`)}
        </Typography>
      )}
    </Box>
  );
};

export default InputTextField;
