import React, { KeyboardEvent, MouseEvent, useEffect, useState } from 'react';
import IMission from '../../types/mission.type';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';
import MissionRow from '../row/MissionRow.component';
import { Pagination, Typography } from '@mui/material';
import { BookingsTabs } from '../../types/bookings-tab.enum';
import usePermissions from '../../config/usePermission';
import { PERMISSIONS } from '../../types/permissions.enum';
import InputText from '../form/inputs/InputText.component';
import searchIcon from '../../assets/img/icon/search.svg';
import refreshIcon from '../../assets/img/icon/refresh.svg';
import emptyList from '../../assets/img/icon/empty-list.svg';
import EventBus from "../../common/EventBus";
import MissionArrayBookerFilterComponent from '../../domain/mission/component/mission-array-booker-filter.component';

export interface Props {
  missions: IMission[];
  handleTabClick: (tabIndex: BookingsTabs) => void;
  handleReload: (
    search?: string,
    orderBy?: string,
    direction?: string,
    index?: number,
    limit?: number,
    contactId?: number[],
  ) => void;
  activeTab: BookingsTabs;
}

export enum SortDirection {
  ASC = 'asc',
  DESC = 'desc',
  NONE = '',
}

const MissionArrayComponent = (props: Props) => {
  const { t } = useTranslation('ns1', { i18n });
  const [missions, setMissions] = useState<IMission[] | null>(null);
  const [numberOfMissionPerPage] = useState(10);
  const [numberOfPage, setNumberOfPage] = useState(1);
  const [activePage, setActivePage] = useState(1);
  const hasPermissionPostRequest = usePermissions(PERMISSIONS['Workflow.Missions.PostRequest']);
  const hasPermissionQuotationRead = usePermissions(PERMISSIONS['Quotes.Read']);
  const hasPermissionMissionCreate = usePermissions(PERMISSIONS['Missions.Create']);
  const hasPermissionReadOthers = usePermissions(PERMISSIONS['Missions.ReadOthers']);
  const [search, setSearch] = useState('');
  const [typing, setTyping] = useState(false);
  const [direction, setDirection] = useState<SortDirection>(SortDirection.NONE);
  const [orderBy, setOrderBy] = useState<string>('');
  const [paginate, setPaginate] = useState(true);
  const [displayPrice, setDisplayPrice] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [contactId, setContactId] = useState<number[]>([]);

  useEffect(() => {
    EventBus.on('loading', loading);
    EventBus.on('notLoading', notLoading);
  }, []);

  const loading = () => {
    setIsLoading(true);
  };

  const notLoading = () => {
    setIsLoading(false);
  };

  useEffect(() => {
    setMissions(props.missions);
  }, [props.missions]);

  const handleChangePage = (event: React.ChangeEvent<unknown>, value: number) => {
    const index = (value - 1) * numberOfMissionPerPage + 1;
    setMissions(null);
    props.handleReload(search, orderBy, direction, index, numberOfMissionPerPage, contactId);
    setActivePage(value);
    if (numberOfPage < value) {
      setNumberOfPage(value);
    }
  };

  const handleSearch = (
    e: MouseEvent<HTMLDivElement> | KeyboardEvent<HTMLInputElement> | null = null,
  ) => {
    setMissions(null);
    setActivePage(1);
    setNumberOfPage(1);
    props.handleReload(search, orderBy, direction, 1, numberOfMissionPerPage, contactId);
  };

  const handleTabClick = (tab: BookingsTabs) => {
    setSearch('');
    setOrderBy('');
    setActivePage(1);
    setNumberOfPage(1);
    setContactId([]);
    props.handleTabClick(tab);
  };

  const handleChangeFilter = (contactId: number[]) => {
    setContactId(contactId);
    props.handleReload(search, orderBy, direction, 1, numberOfMissionPerPage, contactId);
  }

  const handleSort = (name: string) => {
    setOrderBy(name);
    if (direction === SortDirection.NONE) {
      setDirection(SortDirection.ASC);
    } else if (direction === SortDirection.ASC) {
      setDirection(SortDirection.DESC);
    } else {
      setDirection(SortDirection.NONE);
    }
  };

  useEffect(() => {
    props.handleReload(
      search,
      orderBy,
      direction,
      (activePage - 1) * numberOfMissionPerPage + 1,
      numberOfMissionPerPage,
      contactId,
    );
  }, [direction]);

  useEffect(() => {
    if (props.activeTab == BookingsTabs.BookingRequests) {
      setPaginate(false);
    } else {
      setPaginate(true);
    }
  }, [props.activeTab]);

  return (
    <div className="mission-array" data-testid="mission-array">
      <div className="mission-array__header">
        <div className="mission-array__header__tab">
          <div
            id="tab1"
            data-testid="tab1"
            className={`tab ${props.activeTab === BookingsTabs.TodayBookings ? 'active' : ''}`}
            onClick={() => handleTabClick(BookingsTabs.TodayBookings)}
          >
            <div className="tab__title">
              {t('components.booking.tabs.TodayBookings.title')}
            </div>
            <img className={`reload ${props.activeTab === BookingsTabs.TodayBookings ? 'active' : ''}`}
                 src={refreshIcon} alt="" />
          </div>
          <div
            id="tab2"
            data-testid="tab2"
            className={`tab ${props.activeTab === BookingsTabs.UpcomingBookings ? 'active' : ''}`}
            onClick={() => handleTabClick(BookingsTabs.UpcomingBookings)}
          >
            <div className="tab__title">
              {t('components.booking.tabs.UpcomingBookings.title')}
            </div>
            <img className={`reload ${props.activeTab === BookingsTabs.UpcomingBookings ? 'active' : ''}`}
                 src={refreshIcon} alt="" />
          </div>
          {hasPermissionPostRequest && (
            <div
              id="tab3"
              data-testid="tab3"
              className={`tab ${props.activeTab === BookingsTabs.BookingRequests ? 'active' : ''}`}
              onClick={() => handleTabClick(BookingsTabs.BookingRequests)}
            >

              <div className="tab__title">
                {t('components.booking.tabs.BookingRequests.title')}
              </div>
              <img className={`reload ${props.activeTab === BookingsTabs.BookingRequests ? 'active' : ''}`}
                   src={refreshIcon} alt="" />
            </div>
          )}
          {(hasPermissionQuotationRead || (!hasPermissionPostRequest && hasPermissionMissionCreate)) && (
            <div
              id="tab4"
              data-testid="tab4"
              className={`tab ${
                props.activeTab === BookingsTabs.QuotationsRequests ? 'active' : ''
              }`}
              onClick={() => handleTabClick(BookingsTabs.QuotationsRequests)}
            >
              <div className="tab__title">
                {hasPermissionPostRequest
                  ? t('components.booking.tabs.QuotationsRequests.title-PostRequest')
                  : t('components.booking.tabs.QuotationsRequests.title')}
              </div>
              <img className={`reload ${
                props.activeTab === BookingsTabs.QuotationsRequests ? 'active' : ''
              }`} src={refreshIcon} alt="" />
            </div>
          )}
          <div
            id="tab5"
            data-testid="tab5"
            className={`tab ${props.activeTab === BookingsTabs.PastBookings ? 'active' : ''}`}
            onClick={() => handleTabClick(BookingsTabs.PastBookings)}
          >
            <div className="tab__title">
              {t('components.booking.tabs.PastBookings.title')}
            </div>
            <img className={`reload ${props.activeTab === BookingsTabs.PastBookings ? 'active' : ''}`} src={refreshIcon}
                 alt="" />
          </div>
        </div>
        <div id="searchField">
          <InputText
            label={''}
            name={'test'}
            onBlur={async function(e: React.ChangeEvent<HTMLInputElement>) {
              setTimeout(function() {
                setTyping(false);
              }, 300);
            }}
            onChange={async function(e: React.ChangeEvent<HTMLInputElement>) {
              setSearch(e.target.value);
              setTyping(true);
            }}
            onKey={function(e: React.KeyboardEvent<HTMLInputElement>) {
              if (e.key === 'Enter') {
                handleSearch();
              }
            }}
            id={'search-filter'}
            class_name={'search-filter'}
            placeholder={t('components.booking.search.placeholder')}
            prefix=""
            suffix={!typing ? searchIcon : ''}
            data-testid={'passenger-search'}
            value={search}
          />
          {typing && (
            <div className="search-filter__press-enter" onClick={handleSearch}>
              <span data-testid="press-enter">{t('components.booking.search.pressEnter')}</span>
              <img src={searchIcon} alt="" />
            </div>
          )}
        </div>
      </div>
      <div className="line">
        <div
          className={`line__tab--${
            props.activeTab +
            1 +
            (!hasPermissionPostRequest && props.activeTab > 2 ? -1 : 0) +
            (!hasPermissionQuotationRead && props.activeTab > 3 ? -1 : 0)
          }`}
        ></div>
      </div>
      {hasPermissionReadOthers === true && (
        <div>
          <MissionArrayBookerFilterComponent onChange={handleChangeFilter} contactId={contactId} />
        </div>
      )}
      <div className="mission-array__table">
        <div className="mission-array__table__header">
          {props.activeTab === BookingsTabs.QuotationsRequests && (
            <div className="mission-array__table__header-element--type">
              <Typography variant="tableHeader">{t('components.booking.row.type')}</Typography>
            </div>
          )}
          <div className="mission-array__table__header-element--account">
            <Typography variant="tableHeader">{t('components.booking.row.account')}</Typography>
          </div>
          <div className="mission-array__table__header-element--ride">
            <Typography variant="tableHeader">{t('components.booking.row.ride')}</Typography>
            <div
              id="order-by-ride"
              data-testid="order-by-ride"
              onClick={() => handleSort('ride')}
              className={'header-arrow header-arrow__' + (orderBy === 'ride' && direction)}
            ></div>
          </div>
          <div className="mission-array__table__header-element--date">
            <Typography variant="tableHeader">{t('components.booking.row.date')}</Typography>
            <div
              id="order-by-date"
              data-testid="order-by-date"
              onClick={() => handleSort('date')}
              className={'header-arrow header-arrow__' + (orderBy === 'date' && direction)}
            ></div>
          </div>
          <div className="mission-array__table__header-element--time">
            <Typography variant="tableHeader">{t('components.booking.row.time')}</Typography>
          </div>
          <div className="mission-array__table__header-element--passenger">
            <Typography variant="tableHeader">{t('components.booking.row.passenger')}</Typography>
          </div>
          <div className="mission-array__table__header-element--trip">
            <Typography variant="tableHeader">{t('components.booking.row.itinerary')}</Typography>
          </div>
          <div className="mission-array__table__header-element--service">
            <Typography variant="tableHeader">{t('components.booking.row.service')}</Typography>
          </div>
          <div className="mission-array__table__header-element--vehicle">
            <Typography variant="tableHeader">{t('components.booking.row.vehicle')}</Typography>
          </div>
          <div className="mission-array__table__header-element--driver">
            <Typography variant="tableHeader">{t('components.booking.row.driver')}</Typography>
          </div>
          {(props.activeTab === BookingsTabs.PastBookings ||
              props.activeTab === BookingsTabs.QuotationsRequests) &&
            displayPrice && (
              <div className="mission-array__table__header-element--price">
                <Typography variant="tableHeader">{t('components.booking.row.price')}</Typography>
              </div>
            )}
          {props.activeTab !== BookingsTabs.PastBookings &&
            props.activeTab !== BookingsTabs.BookingRequests && (
              <div className="mission-array__table__header-element--actions">
                <Typography variant="tableHeader">{t('components.booking.row.actions')}</Typography>
              </div>
            )}
          <div className="mission-array__table__header-element--status">
            <Typography variant="tableHeader">{t('components.booking.row.status')}</Typography>
          </div>

          <div className="mission-array__table__header-element--empty-space"></div>
        </div>
        <div className="mission-array__table__body">
          {missions &&
            missions.map((mission, idx) => {
              if (idx < numberOfMissionPerPage || !paginate)
                return (
                  <MissionRow
                    data-testid="mission-row"
                    mission={mission}
                    activeTab={props.activeTab}
                    key={mission.id}
                    handleReload={() =>
                      props.handleReload(
                        search,
                        orderBy,
                        direction,
                        (activePage - 1) * numberOfMissionPerPage + 1,
                        numberOfMissionPerPage,
                        contactId,
                      )
                    }
                  />
                );
              else return null;
            })}
          {!isLoading && missions && missions.length === 0 && (
            <div data-testid="mission-row-empty" className="mission-row--empty">
              <img src={emptyList} />
              <span className="mission-row--empty--title">
                {t('components.booking.tabs.' + BookingsTabs[props.activeTab] + '.empty.title')}
              </span>
              <span className="mission-row--empty--subtitle">
                {t('components.booking.tabs.' + BookingsTabs[props.activeTab] + '.empty.subtitle')}
              </span>
            </div>
          )}
        </div>
        {paginate && (
          <Pagination
            className="pagination"
            count={
              numberOfPage +
              (missions && missions.length > numberOfMissionPerPage && numberOfPage == activePage
                ? 1
                : 0)
            }
            page={activePage}
            onChange={handleChangePage}
          />
        )}
      </div>
    </div>
  );
};

export default MissionArrayComponent;
