import { useQuery } from '@tanstack/react-query';
import HabilitationApi from '../api/habilitation.api';
import { useMsal } from '@azure/msal-react';
import { getAccessToken } from '../../../utils/getAccessToken';

export function useRetrieveAllBooker() {
  const { instance } = useMsal();

  const { data, isLoading, error } = useQuery({
    queryKey: ['habilitation', 'booker'],
    queryFn: async () => {
      const accessToken = await getAccessToken(instance);
      if (!accessToken) {
        throw new Error("No access token found");
      }
      return HabilitationApi.getBookers(accessToken);
    },
    initialData: [],
  });

  return { data, isLoading, error };
}
