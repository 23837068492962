import React from 'react';
import ArticleForm from '../../../../components/article/ArticleForm.component';
import profile from '../../../../assets/img/icon/profile.svg';
import children from '../../../../assets/img/icon/children.svg';
import infant from '../../../../assets/img/icon/infant.svg';
import childSeat from '../../../../assets/img/icon/child-seat.svg';
import babySeat from '../../../../assets/img/icon/baby-seat.svg';
import cabinBaggage from '../../../../assets/img/icon/cabin-baggage.svg';
import checkedBaggage from '../../../../assets/img/icon/checked-baggage.svg';
import whellchair from '../../../../assets/img/icon/whellchair.svg';
import IPassenger from '../../../../types/passenger.type';
import PassengerCredentialsComponent from './PassengerCredentials.component';
import usePassengerDetailsForm from '../../hook/passenger-details/use-passenger-details-form.hook';

interface Props {
  passenger: IPassenger;
  handleGetPassenger: (value?: string) => void;
  handleSidebarPassengerFormOpen: (passenger?: IPassenger) => void;
  handle_change: (value: IPassenger) => void;
  handlePassengerDetailsSidebar: (passenger: IPassenger) => void;
  getPassengerLabel: (passenger: IPassenger) => string;
  passengersAutocompletion: IPassenger[] | undefined;
  createPassengerFromString: (lastName: string) => void;
  updatePassengerFormPhone: (phone: string) => void;
  updatePassengerFormCivility: (civility: string) => void;
}

const PassengerDetailsFormComponent: React.FC<Props> = (props) => {
  const {
    t,
    passengerDetails,
    maxChildSeats,
    maxBabySeats,
    updatePassenger,
    setMaxChildSeats,
    setMaxBabySeats,
  } = usePassengerDetailsForm(props);

  return (
    <div className='passenger-details-form' data-testid='passenger-details-form'>
      <h6>{t(`form.passenger.details.title-add-additional-passengers`)}</h6>
      <PassengerCredentialsComponent 
        passenger={props.passenger}
        handleGetPassenger={props.handleGetPassenger}
        handleClickOnInputSuffix={props.handleSidebarPassengerFormOpen}
        handle_change={props.handle_change}
        getPassengerLabel={props.getPassengerLabel}
        passengersAutocompletion={props.passengersAutocompletion}
        isInSidebar={true}
        createPassengerFromString={props.createPassengerFromString}
        handlePassengerDetailsSidebar={props.handlePassengerDetailsSidebar}
        updatePassengerFormPhone={props.updatePassengerFormPhone}
        updatePassengerFormCivility={props.updatePassengerFormCivility}
      />
      <ArticleForm
        id='adults'
        sufIcon={profile}
        title={t(`form.passenger.details.adult`)}
        min={0}
        update={(e) => {
          updatePassenger('adults', e);
        }}
        value={passengerDetails?.adults ?? 0}
      />
      <ArticleForm
        id='childrens'
        sufIcon={children}
        title={t(`form.passenger.details.children`)}
        subTitle={t(`form.passenger.details.children-age`)}
        min={0}
        update={(e) => {
          updatePassenger('childrens', e);
          setMaxChildSeats(e);
        }}
        value={passengerDetails?.childrens ?? 0}
      />
      <ArticleForm
        id='childSeats'
        titleIcon={childSeat}
        title={t(`form.passenger.details.child-seat`)}
        min={0}
        max={maxChildSeats}
        update={(e) => {
          updatePassenger('childSeats', e);
        }}
        value={passengerDetails?.childSeats ?? 0}
      />
      <ArticleForm
        id='infants'
        sufIcon={infant}
        title={t(`form.passenger.details.infant`)}
        subTitle={t(`form.passenger.details.infant-age`)}
        min={0}
        update={(e) => {
          updatePassenger('infants', e);
          setMaxBabySeats(e);
        }}
        value={passengerDetails?.infants ?? 0}
      />
      <ArticleForm
        id='babySeats'
        titleIcon={babySeat}
        title={t(`form.passenger.details.baby-seat`)}
        min={0}
        max={maxBabySeats}
        update={(e) => {
          updatePassenger('babySeats', e);
        }}
        value={passengerDetails?.babySeats ?? 0}
      />
      <h6>{t(`form.passenger.details.title-add-luggages`)}</h6>
      <ArticleForm
        id='cabinBaggages'
        sufIcon={cabinBaggage}
        title={t(`form.passenger.details.cabin-baggage`)}
        min={0}
        update={(e) => {
          updatePassenger('cabinBaggages', e);
        }}
        value={passengerDetails?.cabinBaggages ?? 0}
      />
      <ArticleForm
        id='checkedBaggages'
        sufIcon={checkedBaggage}
        title={t(`form.passenger.details.checked-baggage`)}
        min={0}
        update={(e) => {
          updatePassenger('checkedBaggages', e);
        }}
        value={passengerDetails?.checkedBaggages ?? 0}
      />
      <ArticleForm
        id='whellchairs'
        sufIcon={whellchair}
        title={t(`form.passenger.details.wheelchair`)}
        min={0}
        update={(e) => {
          updatePassenger('whellchairs', e);
        }}
        value={passengerDetails?.whellchairs ?? 0}
      />
    </div>
  );
};

export default PassengerDetailsFormComponent;
